import { Controller } from 'stimulus'

export default class ReportControl_ShowController extends Controller {
  static targets = ['policyTemplate', 'taskTemplate', 'policyTemplateSelect', 'taskTemplateSelect']

  changePolicyTemplate() {
    let options
    const selectedValue = this.policyTemplateSelectTarget.value

    if (selectedValue === '') {
      $(this.taskTemplateTargets).show()
    } else {
      options = this.querySelector('#report-control-task-templates-form', selectedValue, 'policy')

      $('#report-control-task-templates-form option:not(:selected)').hide()
      $(options).show()
    }
  }

  querySelector(formId, selectedId, template) {
    return `${formId} option[data-${template}-template-id='${selectedId}']`
  }
}
