
import { Controller } from 'stimulus'

export default class DataStoreController extends Controller {
  connect() {
    this.dataStore = {}
    this.observers = {}
  }

  registerObserver(path, observer) {
    if (_.blank(this.observers[path])) {
      this.observers[path] = []
    }

    if (this.observers[path].indexOf(observer) === -1) {
      this.observers[path].push(observer)
      observer.updateState()
    }
  }

  add(path, ...values) {
    const data = _.get(this.dataStore, path, [])
    let dirty = false

    values.forEach((value) => {
      if (data.indexOf(value) === -1) {
        data.push(value)
        dirty = true
      }
    })

    if (dirty) {
      this.set(path, data.slice(0))
    }

    return values
  }

  remove(path, ...values) {
    let data = _.get(this.dataStore, path, []),
      dirty = false

    values.forEach((value) => {
      if (data.indexOf(value) !== -1) {
        data = _.pull(data, value)
        dirty = true
      }
    })

    if (dirty) {
      this.set(path, data.slice(0))
    }

    return values
  }

  set(path, value) {
    _.set(this.dataStore, path, value)

    this._broadcast(path)

    return value
  }

  get(path) {
    return _.get(this.dataStore, path)
  }

  clear() {
    this.dataStore = {}

    _.forOwn(this.observers, (values) => {
      values.forEach((observer) => {
        _.defer(() => { observer.updateState() })
      })
    })
  }

  _broadcast(path) {
    if (_.present(this.observers[path])) {
      this.observers[path].forEach((observer) => {
        _.defer(() => { observer.updateState() })
      })
    }
  }
}
