
import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class BusinessContinuityExport_DeleteSectionController extends Controller {
  remove() {
    const $section = $(this.element).closest('.j-dynamic-section'),
      $counter = $section.closest('.j-dynamic-section-container').find('.j-dynamic-section-counter')

    $section.remove()

    $counter.val(Math.max(h.parseData($counter.val()) - 1), 0)
  }
}
