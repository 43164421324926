
import { Controller } from 'stimulus'

export default class DynamicGreetingController extends Controller {
  static targets = ['greeting']

  connect() {
    this.createGreetings()
  }

  createGreetings() {
    this.greetingTargets.forEach((greeting) => {
      if (_.isEmpty(greeting.dataset.name))  {
        greeting.innerHTML = `${this.salutation()}!`
      } else {
        greeting.innerHTML = `${this.salutation()} ${greeting.dataset.name}!`
      }
    })
  }

  salutation() {
    const hour = new Date().getHours()

    if (hour < 12) {
      return 'Good Morning'
    }

    if (hour < 18) {
      return 'Good Afternoon'
    }

    return 'Good Evening'
  }
}
