
import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class SaveDraftController extends Controller {
  static targets = ['textArea']

  connect() {
    this._channel = SecuricyApp.cable.subscriptions.create({
      channel: 'SaveDraftChannel',
      resource_id: this._resourceId,
      resource_type: this._resourceType
    })

    if (this.hasTextAreaTarget) {
      _.defer(() => {
        this._changeEvent = () => {
          this._runDebounced()
        }

        this._codemirror.on('change', this._changeEvent)
      })
    }
  }

  disconnect() {
    this._codemirror.off('change', this._changeEvent)

    if (this._charactersTyped) {
      this._charactersTyped.cancel()
    }

    this._channel.unsubscribe()
  }

  run() {
    if (!this.hasTextAreaTarget) {
      return
    }

    if (this._codemirror.isReadOnly()) {
      return
    }

    this._channel.perform('characters_typed', {
      content: this.textAreaTarget.value
    })
  }

  _runDebounced = _.debounce(() => {
    this.run()
  }, 10000)

  get _codemirror() {
    const { editor } = this._markdownEditorFieldController

    return editor.codemirror
  }

  get _markdownEditorFieldController() {
    return h.getParentController(this.textAreaTarget, 'markdown-editor-field')
  }

  get _resourceId() {
    return this.element.dataset.resourceId
  }

  get _resourceType() {
    return this.element.dataset.resourceType
  }
}
