import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class FilterListController extends Controller {
  static targets = [
    'input',
    'content',
    'rowTemplate',
    'errorMessageTemplate'
  ]

  connect() {
    this._reset()

    if (this._fetchOnLoad) {
      this.updateFilterInput()
    }
  }

  async updateFilterInput() {
    this._filterValue = $(this.inputTarget).val().trim()

    await this._handleInput()
  }

  async _handleInput() {
    if (!this._canFilterExistingData) {
      if (this._fetchOnLoad && _.present(this._fetchResults)) {
        this._filteredResults = this._fetchResults
      } else {
        try {
          await this._fetch()
        } catch (err) {
          return this._renderErrorMessage()
        }
      }
    }

    this._filterExistingResults()
  }

  resetAll() {
    this._reset()
    this.updateFilterInput()
  }

  _updateDisplay() {
    const $content = $(this.contentTarget).html('')

    if (_.isArray(this._filteredResults)) {
      const templates = this._renderRows(this._filteredResults)

      $content.append($(templates.join('')))
    } else if (_.isPlainObject(this._filteredResults)) {
      this._eachSection(this._filteredResults, (section, items) => {
        const templates = this._renderRows(items)

        $content.append($('<div>')
          .append($('<strong>' + section + '</strong>'))
          .append($(templates.join(''))))
      })
    }

    $content.find('.template-rendered[data-render-callback]').each((_k, el) => {
      h.executeExternalAction(el.dataset.renderCallback, el)
    })
  }

  _renderRows(array) {
    const rows = _.map(array, (item) => {
      const { template = '', callback = '' } = this._rowTemplate(item),
        $template = $(template).wrap('<div>')
          .parent()
          .addClass('template-rendered')
          .attr('data-render-callback', callback)

      return $template[0].outerHTML
    })

    return rows
  }

  _rowTemplate(item) {
    if (!this.hasRowTemplateTarget) {
      return {
        template: null,
        callback: null
      }
    }

    if (!this._rowTemplateCompiled) {
      this._rowTemplateCompiled = {}
    }

    if (!this._rowTemplateCompiled[item.id]) {
      this._rowTemplateCompiled[item.id] = h.renderTemplate(this.rowTemplateTarget.innerHTML, item)
    }

    return {
      template: this._rowTemplateCompiled[item.id],
      callback: this.rowTemplateTarget.dataset.renderCallback
    }
  }

  _renderErrorMessage() {
    if (this.hasErrorMessageTemplateTarget) {
      this.contentTarget.innerHTML = h.renderTemplate(this.errorMessageTemplateTarget.innerHTML)
    }
  }

  _eachSection(object, callback) {
    _.each(_.keys(object), (key) => {
      if (!_.isArray(this._filteredResults[key])) {
        return
      }

      if (this._filteredResults[key].length === 0) {
        return
      }

      callback(key, this._filteredResults[key])
    })
  }

  _fetch() {
    return h.fetch(this._fetchUrl, {
      method: 'GET',
      'content-type': 'application/json'
    }).then((response) => {
      if (response.ok) {
        return response.json().then((json) => {
          this._storeResults(json.objects)
        })
      }

      throw response
    })
  }

  _reset() {
    $(this.inputTarget).val('')
    this._filterValue = ''
    this._prevFilterValue = ''
    this._fetchResults = null
    this._filteredResults = null
  }

  _storeResults(objects) {
    this._fetchResults = objects
    this._filteredResults = _.cloneDeep(objects)
  }

  _filterExistingResults() {
    if (_.isArray(this._filteredResults)) {
      this._filteredResults = this._filterArray(this._filteredResults)
    } else if (_.isPlainObject(this._filteredResults)) {
      this._eachSection(this._filteredResults, (section, items) => {
        this._filteredResults[section] = this._filterArray(items)
      })
    }

    this._updateDisplay()
    this._prevFilterValue = this._filterValue
  }

  _filterArray(array) {
    return array.filter((item) => (item.title || '').toLowerCase().includes(this._filterValue))
  }

  get _fetchUrl() {
    const url = this.inputTarget.dataset.fetchUrl,
      parsedUrl = _.parseURL(url),
      filterQuery = encodeURIComponent(this._filterValue)

    if (_.present(parsedUrl.search)) {
      return `${url}&filter_query=${filterQuery}`
    }

    return `${url}?filter_query=${filterQuery}`
  }

  get _canFilterExistingData() {
    return this._filterValue !== this._prevFilterValue &&
      _.present(this._prevFilterValue) &&
      _.startsWith(this._filterValue, this._prevFilterValue)
  }

  get _fetchOnLoad() {
    return h.parseData(this.data.get('fetch-on-load'))
  }
}
