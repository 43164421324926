
import { Controller } from 'stimulus'
import Rollbar from 'rollbar'
import { parseData } from 'lib/helpers'

export default class RollbarController extends Controller {
  static DEFAULT_CONFIG = {
    captureUncaught: true,
    payload: {
      environment: 'development'
    }
  }

  connect() {
    this.rollbar = Rollbar.init(this._config)
    this._setupHandleErrorWrapper()
  }

  disconnect() {
    if (this._oldHandleError) {
      this.application.handleError = this._oldHandleError
    }
  }

  get _config() {
    return _.defaultsDeep(parseData(this.data.get('config')), RollbarController.DEFAULT_CONFIG)
  }

  _setupHandleErrorWrapper() {
    this._oldHandleError = this.application.handleError

    this.application.handleError = _.wrap(this.application.handleError, (proceed, error, message, details) => this._handleError(proceed, error, message, details))
  }

  _handleError(proceed, error, message, details) {
    const oldMessage = error.message

    error.message = `${message} - ${error}`

    try {
      this.rollbar.error(error)
    } finally {
      error.message = oldMessage
    }

    proceed.apply(this.application, [error, message, details])
  }
}
