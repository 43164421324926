import { Controller } from 'stimulus'

export default class DeleteItemController extends Controller {
  static targets = ['name']

  click(event) {
    const deleteData = event.currentTarget.dataset

    $(deleteData.deleteItemForm)
      .attr('action',
        Routes.railsAdminDeletePath(
          deleteData.deleteItemModel,
          deleteData.deleteItemObjectId
        )
      )

    if (this.hasNameTarget && !_.isNull(deleteData.deleteItemObjectName)) {
      $(this.nameTarget)
        .html(deleteData.deleteItemObjectName)
        .show()
    }
  }
}
