import { Controller } from 'stimulus'

export default class RecurringTaskController extends Controller {
  static targets = ['startDate', 'periodUnit', 'numPeriodUnits', 'dueDate', 'toggle', 'toggleInput']

  connect() {
    $([this.numPeriodUnitsTarget, this.periodUnitTarget]).on('change', () => {
      this.startDateUpdated()
    })

    $(this.startDateTarget).datepicker({
      startDate: moment().toDate()
    }).on('changeDate', () => {
      this.startDateUpdated()
    })

    $(this.dueDateTarget).datepicker({
      startDate: moment().toDate()
    })
  }

  toggleRequired() {
    $([this.numPeriodUnitsTarget, this.periodUnitTarget]).attr(
      'required', this.toggleInputTarget.checked
    )

    if (this.toggleInputTarget.checked) {
      this.numPeriodUnitsTarget.value = this.numPeriodUnitsTarget.dataset.originalValue
      this.periodUnitTarget.value = this.periodUnitTarget.dataset.originalValue
    } else {
      this.numPeriodUnitsTarget.value = ''
      this.periodUnitTarget.value = ''

      this._clearDueDateRange()
    }
  }

  startDateUpdated() {
    const startDate = moment(this.startDateTarget.value),
      numPeriodUnits = _.parseInt(this.numPeriodUnitsTarget.value),
      periodUnit = this.periodUnitTarget.value,
      maxDueDate = startDate

    maxDueDate.add(numPeriodUnits, periodUnit)

    $(this.dueDateTarget).val('')
    $(this.dueDateTarget).datepicker('setStartDate', moment(this.startDateTarget.value).toDate())
    $(this.dueDateTarget).datepicker('setEndDate', maxDueDate.toDate())
  }

  _clearDueDateRange() {
    $(this.dueDateTarget).datepicker('setStartDate', null)
    $(this.dueDateTarget).datepicker('setEndDate', null)
  }
}
