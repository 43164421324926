
import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class SelectAllController extends Controller {

  static targets = ['selectAll', 'selectItem']

  connect() {
    const dataStore = this._dataStoreController

    dataStore.registerObserver(this._dataStorePath, this)
  }

  toggle() {
    const dataStore = this._dataStoreController

    if (h.parseData(this.selectAllTarget.dataset.checked)) {
      dataStore.set(this._dataStorePath, _.difference(this._selectedIds, this._interactiveIds))
    } else {
      dataStore.set(this._dataStorePath, _.union(this._selectedIds, this._interactiveIds))
    }
  }

  updateState() {
    const values = this._selectedIds,
      interactiveIds = this._interactiveIds

    if (interactiveIds.length === 0) {
      this.selectAllTarget.dataset.checked = false
      this.selectAllTarget.classList.add('no-click')
      $(this.selectAllTarget).parent().addClass('disabled')
    } else {
      this.selectAllTarget.dataset.checked = _.intersection(values, this._interactiveIds).length === this._interactiveIds.length
      this.selectAllTarget.classList.remove('no-click')
      $(this.selectAllTarget).parent().removeClass('disabled')
    }
  }

  get _interactiveIds() {
    const visibleTargets = _.filter(this.selectItemTargets, (target) => {
      return !target.classList.contains('no-click')
        && !target.classList.contains('toggle-out')
        && _.blank($(target).parents('.toggle-out')[0])
        && !h.parseData(target.dataset.locked)
        && (target.offsetWidth > 0 || target.offsetHeight > 0)
    })

    return _.map(visibleTargets, (target) => {
      return target.querySelector('input[type=hidden]').value
    })
  }

  get _selectedIds() {
    let values = this._dataStoreController.get(this._dataStorePath)

    if (_.blank(values)) {
      values = []
    }

    return values
  }

  get _dataStorePath() {
    return this.data.get('dataStorePath')
  }

  get _dataStoreController() {
    return h.getParentController(this.element, 'data-store')
  }
}
