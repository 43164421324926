
import { Controller } from 'stimulus'

export default class SearchFilterController extends Controller {
  static targets = [ 'input', 'filter' ]

  keyup() {
    if (_.blank(this.inputTarget.value)) {
      _.each(this.filterTargets, (element) => {
        $(element).show()
      })

      return true
    }

    const re = new RegExp(
      _(this.inputTarget.value)
        .split('*')
        .map(_.escapeRegExp)
        .join('.*'),
      'i'
    )

    _.each(this.filterTargets, (element) => {
      $(element).toggle(this._toggleCondition(element, re))
    })
  }

  _toggleCondition(element, re) {
    return _.present(element.dataset.filterValue.match(re))
  }
}
