
import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class BusinessContinuityExport_ProcessCheckController extends Controller {
  static targets = ['container', 'doneTemplate']

  click(event) {
    event.preventDefault()

    h.openLocation($(event.target).attr('href'))
  }

  connect() {
    this._planExportProcessCheckChannel = SecuricyApp.cable.subscriptions.create({
      channel: 'BusinessContinuityExport::ProcessCheckChannel',
      export_ticket: this.exportTicket
    }, {
      received: (data) => this.handleCheck(data)
    })

    this._pollInterval = setInterval(() => this.poll(), 5000)
  }

  poll() {
    this._planExportProcessCheckChannel.perform('check')
  }

  handleCheck(data) {
    if (data) {
      this.containerTarget.innerHTML = h.renderTemplate(this._doneTemplate, data.generated_plan)

      this.disconnect()
    }
  }

  disconnect() {
    clearTimeout(this._pollInterval)

    this._planExportProcessCheckChannel.unsubscribe()
  }

  get exportTicket() {
    return this.data.get('exportTicket')
  }

  get _doneTemplate() {
    return this.doneTemplateTarget.innerHTML
  }
}
