
import { Controller } from 'stimulus'
import { renderTemplate } from 'lib/helpers'

export default class Configurator_AddableListController extends Controller {
  static targets = ['list', 'item', 'collector', 'template']

  addItem(event) {
    var newLabel = this.collectorTarget.value,
      newItem

    event.preventDefault()

    if (_.blank(newLabel)) {
      return
    }

    newItem = renderTemplate(this.templateTarget.innerHTML, {
      index: this._nextIndex,
      label: this.collectorTarget.value
    })

    this.listTarget.insertAdjacentHTML('beforeend', newItem)
    this.collectorTarget.value = ''
  }

  get _nextIndex() {
    return this.itemTargets.length
  }
}
