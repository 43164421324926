import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class Policy_ShowController extends Controller {
  static targets = ['status', 'statusIcon']

  refreshChangeableHtml() {
    h.callStimulusAction('.info-sidebar', 'refresh-content', 'refreshContent')
    h.callStimulusAction('.model-show-container', 'tab', 'refreshCurrentTab')
  }
}
