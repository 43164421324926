
import { Controller } from 'stimulus'
import Sortable from 'sortablejs'
import { parseData } from 'lib/helpers'


export default class SortableController extends Controller {
  static DEFAULT_OPTIONS = {
    ghostClass: 'c-sortable__item--ghost',
    chosenClass: 'c-sortable__item--chosen',
    dragClass: 'c-sortable__item--drag'
  }

  connect() {
    this.sortable = Sortable.create(this.element, this._options)
  }

  get _options() {
    return { ...SortableController.DEFAULT_OPTIONS, ...parseData(this.data.get('options')) }
  }
}
