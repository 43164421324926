
import { Controller } from 'stimulus'

export default class DeviseAlertController extends Controller {
  static targets = ['alert']

  dismiss() {
    this.alertTarget
      .parentNode
      .removeChild(
        this.alertTarget
      )
  }
}
