import FileAttachmentController from 'controllers/file_attachment_controller'

export default class ImageAttachmentController extends FileAttachmentController {
  static targets = ['thumbnail', 'thumbnailLabel', 'delete', ...FileAttachmentController.targets]

  select() {
    super.select()
    this._thumbnail = this._file
  }

  clear() {
    if (this.hasDeleteTarget) {
      $(this.deleteTarget).hide()
    }

    this.inputTarget.value = ''

    if (this.hasThumbnailTarget) {
      this.thumbnailTarget.classList.add('disabled')
      this.thumbnailLabelTarget.innerHTML = `Removed ${this.data.get('label')}`
    }
  }

  set _thumbnail(file) {
    const reader = new FileReader()

    if (!this.hasThumbnailTarget) {
      return
    }

    this.thumbnailTarget.classList.remove('disabled')

    reader.readAsDataURL(file)
    reader.onload = () => {
      this.thumbnailTarget.style.backgroundImage = `url('${reader.result}')`
      this.thumbnailTarget.style.backgroundSize = 'cover'
      this.thumbnailTarget.style.backgroundPosition = 'center center'
      this.thumbnailLabelTarget.innerHTML = `Selected ${this.data.get('label')}`
    }
  }
}
