import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class PaginationController extends Controller {
  static targets = ['per', 'jumpToDropDown', 'jumpToPage']

  changePer() {
    const per = this.perTarget.value,
      params = this._filterParams('per', 'page')

    params.push({
      'name': 'per',
      'value': per
    })

    params.push({
      'name': 'page',
      'value': 1
    })

    this._goToUrlFromParams(params)
  }

  openJumpToDropDown() {
    $(this.jumpToDropDownTarget).toggle()
  }

  jumpTo(event) {
    const controller = h.getController(event.currentTarget.dataset.selector, event.currentTarget.dataset.identifier)

    controller.goToPage(this.jumpToPage)
  }

  submitJumpTo() {
    const params = this._filterParams('page')

    params.push({
      'name': 'page',
      'value': this.jumpToPage
    })

    this._goToUrlFromParams(params)
  }

  _goToUrlFromParams(params) {
    SecuricyApp.window.location = '?' + _.map(params, (pair) => {
      return `${encodeURIComponent(pair.name)}=${encodeURIComponent(pair.value)}`
    }).join('&')
  }

  _filterParams() {
    return _.filter(this.params, (value) =>
      !_.includes(arguments, value.name))
  }

  get params() {
    return _.parseQuery(SecuricyApp.window.location.search)
  }

  get maxPages() {
    return _.parseInt(this.data.get('max-pages'))
  }

  get jumpToPage() {
    const value =  _(_.parseInt(this.jumpToPageTarget.value))
      .atMost(this.maxPages)
      .atLeast(1)
      .value()

    if (_.isNaN(value)) {
      return 1
    }

    return value
  }
}
