import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class Assets_UpdateFieldController extends Controller {
  static targets = ['name', 'updateName', 'confidentiality', 'integrity', 'availability', 'vulnerability', 'riskScore', 'riskScoreClass']

  updateName() {
    $(this.updateNameTarget).html(this.nameTarget.value)
  }

  updateRiskScore() {
    const riskScoreTarget = this.riskScoreTarget, riskScoreClassTarget = this.riskScoreClassTarget

    h.fetch(this._riskScoreUrl, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      response.json().then((json) => {
        riskScoreTarget.innerHTML = json.risk_score + ' Risk'

        $(riskScoreClassTarget)
          .removeClass('low-priority')
          .removeClass('medium-priority')
          .removeClass('high-priority')
          .removeClass('calculating-priority')
          .addClass(json.risk_score.toLowerCase() + '-priority')
      })
    })
  }

  get _riskScoreUrl() {
    return Routes.riskScoreApiV1AssetsPath(this._fetchRiskScoreOptions)
  }

  get _fetchRiskScoreOptions() {
    return {
      organization_asset: {
        confidentiality: this._radioValue(this.confidentialityTargets),
        availability: this._radioValue(this.availabilityTargets),
        integrity: this._radioValue(this.integrityTargets),
        vulnerability: this._radioValue(this.vulnerabilityTargets)
      }
    }
  }

  _radioValue(targets) {
    let checkedTarget = _.find(targets, { checked: true })

    if (checkedTarget === null) {
      checkedTarget = targets[0]
    }

    return checkedTarget.value
  }
}
