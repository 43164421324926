import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class Project_UpdateController extends Controller {
  static targets = ['priorityIndicator']

  newPrioritySelected() {
    const selected_option = $(this.element).find(':selected')[0]
    this._priorityPatch(selected_option.dataset.fetchUrl, $(selected_option).val())
  }

  _priorityPatch(url, priority) {
    h.fetch(url, {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json'
      }
    }).then(() => {
      this.updatePriorityIndicator(priority)
    })
  }

  updatePriorityIndicator(priority) {
    $(this.priorityIndicatorTargets).removeClass('urgent-priority high-priority medium-priority low-priority')
    $(this.priorityIndicatorTargets).addClass(`${priority}-priority`)
  }
}
