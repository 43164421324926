
import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class SlideOutPanelController extends Controller {
  static targets = [
    'menu', 'content', 'overlay',
    'browser', 'headerContent', 'error',
    'page', 'innerContent', 'placeholder'
  ]

  static TRANSITIONS = ['slide-in', 'slide-back', 'fade']

  connect() {
    this._opened = false
    this.loadedUrls = []
    this.currentSlide = 0
    this.breadcrumbs = []
    this.loading = null
    this.forceReload = null
  }

  open(event) {
    const href = event.currentTarget.getAttribute('href')

    this.forceReload = h.parseData(event.currentTarget.dataset.forceReload)

    event.preventDefault()

    this.opened = true

    this._showPlaceholder()

    this._showSlideOutPage(href)
      .then(() => {
        this._hidePlaceholder()
        this.forceReload = null
      })
      .catch(() => this._showErrors())

    this.element.dispatchEvent(new CustomEvent('slideOutPanel_open', {
      bubbles: true,
      cancelable: true
    }))
  }

  close() {
    this.menuTarget.addEventListener('transitionend', () => {
      this.contentTarget.innerHTML = ''
    }, { once: true })

    this.loadedUrls = []
    this.currentSlide = 0
    this.breadcrumbs = []
    this.loading = null
    this.opened = false

    this.element.dispatchEvent(new CustomEvent('slideOutPanel_close', {
      bubbles: true,
      cancelable: true
    }))
  }

  back() {
    const url = _.nth(this.breadcrumbs, -2),
      index = this.loadedUrls.indexOf(url)

    this._transitionTo(index, 'slide-back')
    this.breadcrumbs.pop()
  }

  keydown(event) {
    if (event.key === 'Escape' && this._opened) {
      this.close()
    }
  }

  transitionEnd(event) {
    const index = this.pageTargets.indexOf(event.target)

    if (index === -1) {
      return
    }

    this.pageTargets[index].classList.remove('animate')

    if (index === this.currentSlide) {
      this._updateIndexes()

      event.target.dispatchEvent(new CustomEvent('slideOutPanel_transitionEnd', {
        bubbles: true,
        cancelable: true
      }))
    } else {
      this.pageTargets[index].classList.add('hidden')
    }
  }

  scrollToTop() {
    this.innerContentTargets[this.currentSlide].scrollTop = 0
  }

  async _showSlideOutPage(url) {
    const index = this.loadedUrls.indexOf(url),
      transition = this.pageTargets.length === 0 ? 'fade' : 'slide-in'

    if (_.present(this.loading)) {
      return
    }

    if (index === -1 || this.forceReload) {
      return this._loadSlideOutContent(url)
        .then((new_index) => {
          if (new_index !== -1) {
            this.breadcrumbs.push(url)
            this._transitionTo(new_index, transition)
          }

          return true
        }).catch(() => {
          throw false
        })
    }

    this._transitionTo(index, transition)
    this.breadcrumbs.push(url)

    return Promise.resolve(true)
  }

  async _loadSlideOutContent(url) {
    return new Promise((resolve, reject) => {
      this.loading = url

      h.fetch(this._slideOutUrl(url))
        .then((response) => {
          if (response.ok) {
            response.text().then((html) => {
              if (this.loading === url) {
                this.loading = null
                resolve(this._insertContent(url, html))
              } else {
                resolve(-1)
              }
            })

            return response
          }

          throw response
        }).catch(reject)
    })
  }

  _slideOutUrl(url) {
    const parsedUrl = _.parseURL(url)

    if (_.blank(this.pageTargets)) {
      return url
    }

    if (_.present(parsedUrl.search)) {
      return `${url}&slide_out_scope=page`
    }

    return `${url}?slide_out_scope=page`
  }

  _insertContent(url, html) {
    let index

    if (!this.opened) {
      return -1
    }

    if (this.loadedUrls.indexOf(url) === -1) {
      index = this._appendPage(url, html)
    } else {
      index = this._replacePage(url, html)
    }

    _.defer(() => {
      _.nth(this.pageTargets, -1).dispatchEvent(new CustomEvent('slideOutPanel_pageLoaded', {
        bubbles: true,
        cancelable: true
      }))
    })

    return index
  }

  _replacePage(url, html) {
    let index = this.loadedUrls.length,
      node

    if (this.forceReload) {
      index = this.loadedUrls.indexOf(url)
      node = SecuricyApp.document.createRange().createContextualFragment(html)

      this.browserTarget.replaceChild(node, this.pageTargets[index])
    }

    return index
  }

  _appendPage(url, html) {
    this.loadedUrls.push(url)

    if (this.pageTargets.length > 0) {
      this.browserTarget.insertAdjacentHTML('beforeend', html)
    } else {
      this.contentTarget.innerHTML = html
    }

    return this.loadedUrls.length - 1
  }

  _transitionTo(index, transition = 'slide-in') {
    const oldSlide = this.pageTargets[this.currentSlide],
      newSlide = this.pageTargets[index]

    this.currentSlide = index

    if (oldSlide !== newSlide) {
      this._dismiss(oldSlide, transition)
    }

    this._display(newSlide, transition)
  }

  _dismiss(oldSlide, transition) {
    if (_.blank(oldSlide)) {
      return
    }

    oldSlide.style.zIndex = 0
    oldSlide.classList.remove(..._.without(SlideOutPanelController.TRANSITIONS, transition))
    oldSlide.classList.add(transition)

    this._redraw()

    oldSlide.classList.add('animate', 'dismiss')
  }

  _display(newSlide, transition) {
    if (_.blank(newSlide)) {
      return
    }

    newSlide.style.zIndex = 1
    newSlide.classList.remove('display', 'dismiss', 'hidden', ..._.without(SlideOutPanelController.TRANSITIONS, transition))
    newSlide.classList.add(transition)

    this._redraw()

    newSlide.classList.add('animate', 'display')

    newSlide.dispatchEvent(new CustomEvent('slideOutPanel_transitionStart', {
      bubbles: true,
      cancelable: true
    }))
  }

  // HACK: forces a redraw between class mainpulations so that transitions occur in the expected order
  _redraw() {
    return this.contentTarget.offsetHeight
  }

  set opened(value) {
    if (value) {
      $(SecuricyApp.document.body).addClass('no-overflow')
      $(this.menuTarget).addClass('active')
      this._showOverlay()
    } else {
      $(SecuricyApp.document.body).removeClass('no-overflow')
      $(this.menuTarget).removeClass('active')
      this._hidePlaceholder()
      this._hideErrors()
      this._hideOverlay()
    }

    this._opened = value
  }

  get opened() {
    return this._opened
  }

  _updateIndexes() {
    let targetIndex

    this.pageTargets.forEach((target) => {
      delete target.dataset.slideHistoryIndex
    })

    this.breadcrumbs.forEach((url, index) => {
      targetIndex = this.loadedUrls.indexOf(url)
      this.pageTargets[targetIndex].dataset.slideHistoryIndex = index - this.breadcrumbs.length + 1
    })
  }

  // -- sub-element show/hide handlers -- //

  _showOverlay() {
    this.overlayTarget.classList.add('active')
  }

  _hideOverlay() {
    this.overlayTarget.classList.remove('active')
  }

  _showPlaceholder() {
    this._showOverlay()
    this.contentTarget.classList.add('loading')
    this.placeholderTarget.classList.add('active')
  }

  _hidePlaceholder() {
    this.contentTarget.classList.remove('loading')
    this.placeholderTarget.classList.remove('active')
  }

  _showErrors() {
    $(this.contentTarget).hide()
    this._hidePlaceholder()

    this.errorTarget.classList.add('active')
  }

  _hideErrors() {
    this.errorTarget.classList.remove('active')
    $(this.contentTarget).show()
  }
}
