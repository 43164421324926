
import { Controller } from 'stimulus'

export default class Policies_Show_SignoffController extends Controller {
  static targets = ['button', 'completed']

  completed() {
    $(this.buttonTargets).remove()
    $(this.completedTargets).show()
  }
}
