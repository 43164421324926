
import { Controller } from 'stimulus'

export default class SlideToggleController extends Controller {
  static targets = ['panel', 'button']

  click() {
    this._togglePanels()
    this._toggleButtonText()
  }

  _togglePanels() {
    this.panelTargets.forEach((panel) =>
      $(panel).slideToggle())
  }

  _toggleButtonText() {
    if (_.blank(this.text)) {
      return
    }

    this.buttonText = this.text
  }

  get text() {
    return this.data.get('text')
  }

  set text(value) {
    this.data.set('text', value)
  }

  get buttonText() {
    return $(this.buttonTarget).text()
  }

  set buttonText(value) {
    this.text = this.buttonText
    $(this.buttonTarget).text(value)
  }
}
