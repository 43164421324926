
import { Controller } from 'stimulus'

export default class User_Show_BulkCheckboxController extends Controller {
  static targets = ['checkbox',
    'unassignForm', 'completeForm', 'incompleteForm',
    'unassignButton', 'completeButton', 'incompleteButton'
  ]

  toggle() {
    this._updateButtons()
  }

  clickUnassignButton() {
    this._updateForm(this.unassignFormTarget, this._checkedItems)
  }

  clickCompleteButton() {
    this._updateForm(this.completeFormTarget, this._checkedAndIncompleteItems)
  }

  clickIncompleteButton() {
    this._updateForm(this.incompleteFormTarget, this._checkedAndCompletedItems)
  }

  _updateButtons() {
    this._updateButton(this.unassignButtonTargets, !this._hasCheckedItems)
    this._updateButton(this.completeButtonTargets, !this._hasCheckedAndIncompleteItems)
    this._updateButton(this.incompleteButtonTargets, !this._hasCheckedAndCompletedItems)
  }

  _updateButton(buttonTarget, toggle) {
    $(buttonTarget)
      .toggleClass('no-bulk-checkbox-checked', toggle)
  }

  _itemCompleted(item) {
    return $(item).data('completed')
  }

  _updateForm(formTarget, checkedItems) {
    $(formTarget).html(checkedItems.map((checkbox) =>
      `<input type="hidden" name="bulk_ids[]" value="${checkbox.value}" />`))
  }


  get _checkedItems() {
    return $(_.filter(this.checkboxTargets, 'checked')).filter(':visible').toArray()
  }

  get _hasCheckedItems() {
    return this._checkedItems.length
  }


  get _checkedAndCompletedItems() {
    return _.filter(this._checkedItems, (item) => this._itemCompleted(item))
  }

  get _hasCheckedAndCompletedItems() {
    return this._checkedAndCompletedItems.length
  }


  get _checkedAndIncompleteItems() {
    return _.filter(this._checkedItems, (item) => !this._itemCompleted(item))
  }

  get _hasCheckedAndIncompleteItems() {
    return this._checkedAndIncompleteItems.length
  }
}
