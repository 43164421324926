import LinkedCheckCirclesController from 'controllers/linked_check_circles_controller'

export default class LinkedRadioCirclesController extends LinkedCheckCirclesController {

  connect() {
    const dataStore = this._dataStoreController

    if (this._checked) {
      dataStore.set(this._dataStorePath, this._value)
    }

    dataStore.registerObserver(this._dataStorePath, this)
  }

  check() {
    this._dataStoreController.set(this._dataStorePath, this._value)
  }

  updateState() {
    this._checked = this._dataStoreController.get(this._dataStorePath) === this._value
  }
}
