import LinkedCheckCirclesController from 'controllers/linked_check_circles_controller'
import * as h from 'lib/helpers'

export default class LockableCheckCirclesController extends LinkedCheckCirclesController {
  connect() {
    const dataStore = this._dataStoreController,
      lockedBy = h.parseData(this.element.dataset.lockedBy)

    if (_.present(lockedBy)) {
      dataStore.add(this._lockPath, ...lockedBy)
    }

    if (!this._locked) {
      super.connect()
    }

    dataStore.registerObserver(this._lockPath, this)
  }

  updateState() {
    const selectedValues = this._dataStoreController.get(this._lockPath),
      lockingValues = _.intersection(selectedValues, this._lockingIds)

    this._locked = _.present(lockingValues)

    if (!this._locked) {
      super.updateState()
    }
  }

  set _locked(value) {
    if (this._locked === value) {
      this.element.closest('.circle-button').classList.toggle('circle-button--locked', value)
    }

    this.element.dataset.locked = value
    this.element.closest('.circle-button').classList.toggle('circle-button--locked', value)

    if (value) {
      this._checked = true
    } else {
      this._checked = _.includes(this._dataStoreController.get(this._dataStorePath), this._value)
    }
  }

  get _locked() {
    return h.parseData(this.element.dataset.locked)
  }

  get _lockingIds() {
    return h.parseData(this.element.dataset.lockingIds)
  }

  get _lockPath() {
    return this.element.dataset.lockingScope
  }
}
