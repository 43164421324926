import { Controller } from 'stimulus'

export default class IndexDeleteController extends Controller {
  hide() {
    $(this.element).fadeOut()
  }

  show() {
    $(this.element).fadeIn()
  }
}
