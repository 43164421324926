
import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class Settings_MfaSetupController extends Controller {
  static targets = ['toggleEditButton', 'toggleMfa']

  connect() {
    if (this._mfaSetup) {
      _.defer(() => {
        this._enableMfa()
      })
    }
  }

  _enableMfa() {
    this.toggleEditButtonTarget.click()

    if (!this.toggleMfaTarget.checked) {
      this.toggleMfaTarget.checked = true

      this.toggleMfaTarget.dispatchEvent(new CustomEvent('change', {
        bubbles: true,
        cancelable: true
      }))
    }
  }

  get _mfaSetup() {
    return _.any(this._params, ({ name, value }) =>
      name === 'mfa_setup' && h.parseData(value) === true)
  }

  get _params() {
    return _.parseQuery(SecuricyApp.window.location.search)
  }
}
