import { Controller } from 'stimulus'

export default class ScrollToEnableController extends Controller {
  static targets = ['button', 'content']

  connect() {
    this.contentTarget.addEventListener('scroll', () => this.scroll())

    this.scroll()
  }

  scroll() {
    if (this._shouldEnable()) {
      this._enable()
    }
  }

  _enable() {
    this.buttonTarget.classList.remove('c-button--disabled')
    this.buttonTarget.classList.add('c-button--primary')
    this.buttonTarget.disabled = false
  }

  _shouldEnable() {
    return _.inRange(
      this.contentTarget.offsetHeight + this.contentTarget.scrollTop,
      this.contentTarget.scrollHeight - 2.5,
      this.contentTarget.scrollHeight + 2.5
    )
  }
}
