
import { Controller } from 'stimulus'

export default class Configurator_ConfiguratorPageController extends Controller {
  static targets = ['other', 'otherText', 'input', 'hiddenGroupName', 'form']

  connect() {
    this.clickOption()
  }

  clickOption() {
    if (this.hasOtherTarget && this.hasOtherTextTarget) {
      $(this.otherTextTarget).toggle(this.otherTarget.checked)
      $(this.otherTextTarget).prop('disabled', !this.otherTarget.checked)
      $(this.otherTextTarget).attr('required', this.otherTarget.checked)
    }
  }

  toggleHiddenGroupName(event) {
    $(this.hiddenGroupNameTargets).prop('disabled', true)
    $($(event.currentTarget).parent().find('input[type=hidden]')[0]).prop('disabled', false)
  }

  submit() {
    if (this.hasFormTarget) {
      this.formTarget.submit()
    }
  }
}
