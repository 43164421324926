
import RedirectFetchObserver from 'lib/redirect_fetch_observer'

export default class PaywallFetchObserver extends RedirectFetchObserver {
  observeFetch() {
    if (this._wasRedirected) {
      _.tryIt(SecuricyApp.fullScreenModal, 'open', this.response.url)
      throw this.response
    }
  }

  get _redirectPath() {
    return Routes.settingsUpgradePath()
  }
}
