
import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class ReportingCenter_FilterContentController extends Controller {
  static targets = ['content', 'apply', 'filterCheckbox', 'placeholder']

  connect() {
    this.refreshContent()

    this._filterQuery = ''
    this._filterStates = {}

    this._removeFilter = (event) => {
      const { filterKey, filterObjectId } = event.detail

      this._dataStoreController.remove(filterKey, filterObjectId)

      this.apply(event)
    }

    SecuricyApp.window.addEventListener('ReportingCenter_ActiveFiltersController_removeFilter', this._removeFilter)
  }

  disconnect() {
    SecuricyApp.window.removeEventListener('ReportingCenter_ActiveFiltersController_removeFilter', this._removeFilter)
  }

  apply(event) {
    this._withFilterKey(event, (filterKey) => {
      this._storeFilterState(filterKey)
    })

    this._currentPage = 1

    this._updateSelectedText()
    this.refreshContent()

    h.dispatchCustomEvent(SecuricyApp.window, 'ReportingCenter_FilterContentController_apply')
  }

  filterDropdownOpened(event) {
    event.stopPropagation()

    this._withFilterKey(event, (filterKey) => {
      this._storeFilterState(filterKey)
    })

    h.callStimulusAction($(event.target).closest('.j-filter-dropdown'), 'filter-list', 'resetAll')
  }

  filterDropdownClosed(event) {
    event.stopPropagation()

    this._withFilterKey(event, (filterKey) => {
      this._setFiltersToStoredState(filterKey)
    })
  }

  filterQueryChange(event) {
    const $input = $(event.target)

    if ($input.is('input')) {
      this._filterQuery = $input.val().trim()
    }

    this.apply()
  }

  refreshContent() {
    this._showPlaceholder()
    this._loadContent(this.data.get('url'))
  }

  resetFilters(event) {
    const $filterDropdown = $(event.target).closest('.j-filter-dropdown')

    if ($filterDropdown.length) {
      h.callStimulusAction($filterDropdown, 'filter-list', 'resetAll')
    }

    this._withFilterKey(event, (filterKey) => {
      this._dataStoreController.set(this._filterPath(filterKey), [])
    })

    this.apply(event)
  }

  itemCheckChange(event) {
    const input = event.target,
      path = this._inputPath(input),
      $container = $(input).closest('.j-filter-dropdown')

    if (input.checked) {
      if (input.type === 'radio') {
        _.each($container.find(`input[type="radio"]:not("#${input.id}")`), (el) => {
          this._dataStoreController.remove(this._inputPath(el), el.dataset.filterObjectId)
          el.checked = false
        })
      }

      this._dataStoreController.add(path, input.dataset.filterObjectId)
    } else {
      this._dataStoreController.remove(path, input.dataset.filterObjectId)
    }
  }

  setCheckboxChecked(event) {
    const input = $(event.target).find(`input[data-target="${this.identifier}.filterCheckbox"]`)[0]

    this._setCheckboxChecked(input)
  }

  _setCheckboxChecked(input) {
    const { filterObjectId } = input.dataset,
      items = this._dataStoreController.get(this._inputPath(input))

    input.checked = _.includes(items, filterObjectId) || _.blank(items) && this._isDefaultRadioButton(input)
  }

  _showPlaceholder() {
    if (!this.hasPlaceholderTarget) {
      return
    }

    this.contentTarget.innerHTML = this.placeholderTarget.innerHTML
  }

  _withFilterKey(event, callback) {
    if (!_.present(event)) {
      return
    }

    if (event.detail && event.detail.filterKey) {
      callback(event.detail.filterKey)

      return
    }

    this._getFilterKeys(event.target).forEach(callback)
  }

  /**
   * @param {HTMLElement} element
   * @returns {string[]}
  */
  _getFilterKeys(element) {
    const dropdownFilterKey = this._dropdownFilterKey(element)

    if (!dropdownFilterKey) {
      return element.dataset.filters.split(',')
    }

    return [dropdownFilterKey]
  }

  _storeFilterState(filterKey) {
    this._filterStates[filterKey] = this._dataStoreController.get(this._filterPath(filterKey))
  }

  _setFiltersToStoredState(filterKey) {
    this._dataStoreController.set(this._filterPath(filterKey), this._filterStates[filterKey])
  }

  clickPageLink(event) {
    event.preventDefault()

    this.goToPage(Math.max(1, _.toLength(event.currentTarget.dataset.page)))
  }

  goToPage(page) {
    this._dataStoreController.set(`${this._dataStorePath}.page`, page)
    this.refreshContent()
  }

  _isDefaultRadioButton(input) {
    return $(input).is($(input).closest('.j-filter-dropdown').find('input[type=radio][data-filter-object-id=""]'))
  }

  _dropdownFilterKey(input) {
    return $(input).closest('.j-filter-dropdown').data('filter-key')
  }

  _filterPath(filterKey) {
    return `${this._dataStorePath}.filters.${filterKey}`
  }

  _inputPath(input) {
    return this._filterPath(this._dropdownFilterKey(input))
  }

  _loadContent(url) {
    h.fetch(url, this._fetchOptions)
      .then((response) => {
        if (response.ok) {
          response.text().then((html) => {
            this.contentTarget.innerHTML = html
          })

          return response
        }
      })
  }

  _updateSelectedText() {
    _.each(this._dataStoreController.get(`${this._dataStorePath}.filters`), (values, key) => {
      const size = _.size(_.filter(values, _.present))

      $(`.j-filter-dropdown[data-filter-key="${key}"] .j-filter-counter`)
        .html(size.toString())
        .css('display', size > 0 ? 'flex' : 'none')
    })
  }

  get _fetchOptions() {
    return {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        page: this._currentPage,
        query: this._filterQuery,
        filters: this._filterOptions
      })
    }
  }

  get _currentPage() {
    return Math.max(1, _.defaultTo(this._dataStoreController.get(`${this._dataStorePath}.page`), 1))
  }

  set _currentPage(page) {
    this._dataStoreController.set(`${this._dataStorePath}.page`, page)
  }

  get _filterOptions() {
    return _.defaultTo(this._dataStoreController.get(`${this._dataStorePath}.filters`), {})
  }

  get _dataStorePath() {
    return `${this.identifier}.${this.data.get('section')}`
  }

  get _dataStoreController() {
    return h.getParentController(this.element, 'data-store')
  }
}
