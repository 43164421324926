import { Controller } from 'stimulus'
import Mustache from 'mustache'
import * as h from 'lib/helpers'

export default class ActivityLog_IndexController extends Controller {
  static targets = ['dayTemplate', 'itemTemplate', 'dayContainer', 'day', 'itemContainer', 'loader', 'placeholder']

  static ENTRIES_PER_FETCH = 30

  connect() {
    _.defer(() => {
      h.callStimulusAction(this.loaderTarget, 'button-spinner', 'submit')
    })

    this.page = 1
    this.disabled = false
    this.getActivity()
  }

  getActivity() {
    if (this.disabled) {
      return
    }

    h.fetch(
      Routes.apiV1ActivityLogsPath({
        page: this.page,
        per: ActivityLog_IndexController.ENTRIES_PER_FETCH
      })
    ).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          if (_.blank(json.objects)) {
            this._disableLoader()
            return
          }

          this._addItems(json.objects)

          if (json.remaining === 0) {
            this._disableLoader()
          }
        })

        this.page += 1
        h.callStimulusAction(this.loaderTarget, 'button-spinner', 'stopSpinner')

        if (_.present(this.placeholderTarget)) {
          this.placeholderTarget.remove()
        }
      }
    })
  }

  _disableLoader() {
    this.loaderTarget.classList.add('hidden')
    this.disabled = true
  }

  _addItems(items) {
    this._iterateDays(items, (readableDate, itemsForDate) => {
      this._addItemsForDate(readableDate, itemsForDate)
    })
  }

  _iterateDays(items, fn) {
    const itemsByDate = _.groupBy(items, (item) => item.sortable_date)

    _.keys(itemsByDate).sort().reverse().forEach((date) => {
      fn(itemsByDate[date][0].readable_date, itemsByDate[date])
    })
  }

  _addItemsForDate(date, itemsForDate) {
    const target = this._getDayTarget(date) || this._appendDate(date)

    this._addItemsToTarget(target, itemsForDate)
  }

  _getDayTarget(date) {
    return _.find(this.dayTargets, (target) => target.dataset.date === date)
  }

  _appendDate(date) {
    this.dayContainerTarget.innerHTML += Mustache.render(this._dayTemplateCompiled, {
      date: date
    })

    return this._getDayTarget(date)
  }

  _addItemsToTarget(dayTarget, itemsForDate) {
    const itemContainer = this.itemContainerTargets[_.indexOf(this.dayTargets, dayTarget)]

    _.orderBy(itemsForDate, 'created_at', 'desc').forEach((item) => {
      itemContainer.innerHTML += Mustache.render(this._itemTemplateCompiled, {
        icon: item.icon,
        text: item.text,
        parent: item.parent_context,
        timestamp: item.timestamp,
        path: item.context_path,
        target: item.link_target
      })
    })
  }

  get _dayTemplateCompiled() {
    if (!this.__dayTemplateCompiled) {
      this.__dayTemplateCompiled = this.dayTemplateTarget.innerHTML

      Mustache.parse(this.__dayTemplateCompiled)
    }

    return this.__dayTemplateCompiled
  }

  get _itemTemplateCompiled() {
    if (!this.__itemTemplateCompiled) {
      this.__itemTemplateCompiled = this.itemTemplateTarget.innerHTML

      Mustache.parse(this.__itemTemplateCompiled)
    }

    return this.__itemTemplateCompiled
  }
}
