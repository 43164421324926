import { Controller } from 'stimulus'

import * as h from 'lib/helpers'

export default class ModalConfirmController extends Controller {
  static targets = ['modal', 'title', 'icon', 'text', 'cancel', 'confirm']

  static DEFAULT_OPTIONS = {
    modalConfirmTitle: 'Confirm Action',
    modalConfirmIcon: 'check_circle',
    modalConfirmText: 'Are you sure you want to do this?',
    modalConfirmCancelText: 'Cancel',
    modalConfirmButtonText: 'Confirm',
    modalConfirmButtonClass: 'c-button--confirmation'
  }

  open(event) {
    this._configure(event.currentTarget)

    $(this.modalTarget).modal()
  }

  close() {
    $(this.modalTarget).modal('hide')
  }

  confirm() {
    const confirmAction = this.data.get('confirmAction')

    this.close()

    if (_.present(confirmAction)) {
      h.executeExternalAction(confirmAction, this.data.openElement)
    }
  }

  _configure(element) {
    const options = { ...ModalConfirmController.DEFAULT_OPTIONS, ...element.dataset }

    this.data.openElement = element
    this.data.set('confirmAction', options.modalConfirmAction)

    this.titleTarget.innerHTML = options.modalConfirmTitle
    this.iconTarget.innerHTML = options.modalConfirmIcon
    this.textTarget.innerHTML = options.modalConfirmText
    this.cancelTarget.innerHTML = options.modalConfirmCancelText
    this.confirmTarget.innerHTML = options.modalConfirmButtonText
    this.confirmTarget.classList.add(options.modalConfirmButtonClass)
  }
}
