import { Controller } from 'stimulus'
import { parseData } from 'lib/helpers'

export default class CheckCirclesController extends Controller {
  toggle(event) {
    const element = event.currentTarget,
      checked = parseData(element.dataset.checked)

    element.dataset.checked = !checked

    this._toggleHiddenInput(element, checked)
  }

  _toggleHiddenInput(element, checked) {
    const hiddenInput = element.querySelector('input[type=hidden]')

    if (hiddenInput) {
      hiddenInput.disabled = checked
    }
  }
}
