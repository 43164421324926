
import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class ReportingCenter_ActiveFiltersController extends Controller {
  static targets = ['activeFiltersList']

  connect() {
    this.filters = this.element.dataset.filters.split(',')

    $(this.element).hide()
  }

  updateFilters() {
    const activeFilters = _.flatten(_.map(this._filterData(), (ids, key) => {
      return ids.map(id => [id, key])
        .filter(([id]) => id !== '')
    }))

    $(this.activeFiltersListTarget).html(activeFilters.map(([id, key]) => this._buildChip(id, key)))
    $(this.element).toggle(!_.isEmpty(activeFilters))
  }

  removeFilter(event) {
    const $target = $(event.target),
      $chip = $target.closest('.c-chip'),
      { filterObjectId, filterKey } = $chip[0].dataset

    h.dispatchCustomEvent(SecuricyApp.window, 'ReportingCenter_ActiveFiltersController_removeFilter', {
      filterObjectId,
      filterKey
    })
  }

  _buildChip(filterObjectId, filterPath) {
    const key = this._filterKey(filterPath),
      color = this._chipColor(key),
      $element = $('<div>')
        .addClass('c-chip u-margin-1')
        .attr('data-filter-object-id', filterObjectId)
        .attr('data-filter-key', filterPath)
        .html(this._filterItemText(key, filterObjectId))
        .append($('<i>')
          .addClass('material-icons')
          .attr('data-action', 'click->reporting-center--active-filters#removeFilter')
          .html('close'))

    if (_.present(color)) {
      $element.addClass(`u-bg-${color}`)
    }

    return $element
  }

  _chipColor(key) {
    const index = this.filters.indexOf(key)
    let color

    if (_.present(this.element.dataset.colors)) {
      color = this.element.dataset.colors.split(',')[index]
    }

    if (_.present(color)) {
      return color
    }

    return 'shade-4'
  }

  _filterItemText(key, filterObjectId) {
    const $element = $(`.j-filter-dropdown[data-filter-key="${key}"] .j-filter-option[data-filter-object-id="${filterObjectId}"]`),
      $selectedTemplate = $element.find('.j-selected-template')

    if ($selectedTemplate.length !== 0) {
      return $selectedTemplate.html().trim()
    }

    return $element
      .text()
      .trim()
  }

  _filterData() {
    return this.filters.reduce((obj, key) => {
      const path = this._filterPath(key)

      _.tap(this._dataStoreController.get(path), (value) => {
        if (_.isEmpty(value)) {
          return
        }

        obj[path] = value
      })

      return obj
    }, {})
  }

  _filterPath(filterKey) {
    return `reporting-center--filter-content.${this._section}.filters.${filterKey}`
  }

  _filterKey(filterPath) {
    return /.*\.(.*)/.exec(filterPath)[1]
  }

  get _section() {
    return this.element.dataset.section
  }

  get _dataStorePath() {
    return `${this.identifier}.${this.data.get('section')}`
  }

  get _dataStoreController() {
    return h.getParentController(this.element, 'data-store')
  }
}
