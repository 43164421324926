
import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class Settings_MultiFactorAuthenticationController extends Controller {
  static targets = ['toggle']

  toggle() {
    if (this._checked) {
      h.fetch(Routes.accountMultiFactorAuthenticationEnableSettingsPath(), {
        method: 'PATCH'
      }).then(() => {
        this._openModal()
      })

      return
    }

    h.fetch(Routes.accountMultiFactorAuthenticationDisableSettingsPath(), {
      method: 'PATCH'
    })
  }

  reconfigure() {
    h.fetch(Routes.accountMultiFactorAuthenticationReconfigureSettingsPath(), {
      method: 'PATCH'
    }).then(() => {
      this._openModal()
    })
  }

  _openModal() {
    SecuricyApp.fullScreenModal.open(Routes.accountMultiFactorAuthenticationVerifySettingsPath())
  }

  get _checked() {
    return this.toggleTarget.checked
  }
}
