import { Controller } from 'stimulus'

export default class ToggleVisibilityController extends Controller {
  static targets = ['toggle']

  toggle() {
    if ($(this.toggleTarget).visible()) {
      this.hide()
    } else {
      this.show()
    }
  }

  show() {
    if (this._fade) {
      $(this.toggleTarget).fadeIn('slow')

      return
    }

    $(this.toggleTarget).show()
  }

  hide() {
    if (this._fade) {
      $(this.toggleTarget).fadeOut('slow')

      return
    }

    $(this.toggleTarget).hide()
  }

  get _fade() {
    return _.present($(this.toggleTarget).data('toggle-visibility-fade'))
  }
}
