
import { Controller } from 'stimulus'

export default class ListFilterController extends Controller {
  static targets = ['listItem', 'filter']

  filter() {
    const filters = _.filter(this.filterTargets, 'value')

    if (_.blank(filters)) {
      $(this.listItemTargets).show()

      return
    }

    $(this.listItemTargets).hide()

    $(_.filter(this.listItemTargets, (listItem) => {
      return _.all(filters.map((filter) =>
        listItem.dataset[filter.dataset.filter] === filter.value))
    })).show()
  }

  clear() {
    this.filterTargets.forEach((filter) => {
      filter.value = ''
    })

    this.filter()
  }
}
