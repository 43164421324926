import { Controller } from 'stimulus'
import parser from 'email-addresses'
import { renderTemplate } from 'lib/helpers'
import * as h from 'lib/helpers'

export default class Invitations_New_BulkEmailCollectorController extends Controller {
  static targets = ['collector', 'template', 'list', 'invitation', 'errorMessage', 'email', 'inviteButton']

  collect(event) {
    event.preventDefault()

    this._parseEmails().forEach((string) => {
      this._addEmail(string.trim())
    })

    this._value = ''
    this._handleInviteButtonStatus()
    this._bulkFormConroller.disableAddButton()
  }

  remove(event) {
    $(event.currentTarget).parents('.invited-user')[0].remove()
    this._handleInviteButtonStatus()
  }

  _handleInviteButtonStatus() {
    $(this.inviteButtonTarget)
      .toggleClass('c-button--disabled', !this.hasInvitationTarget)
      .toggleClass('c-button--primary', this.hasInvitationTarget)
      .prop('disabled', !this.hasInvitationTarget)
  }

  _addEmail(email) {
    var newInvitation

    if (this._hasEmail(email) || _.blank(email)) {
      return
    }

    newInvitation = renderTemplate(this.templateTarget.innerHTML, {
      email: email,
      avatar_character: email.charAt(0).toUpperCase()
    })

    if (this.hasInvitationTarget) {
      this.invitationTargets[0].insertAdjacentHTML('beforebegin', newInvitation)
    } else {
      this.listTarget.innerHTML = newInvitation
    }
  }

  _parseEmails() {
    const list = parser.parseAddressList(this._value)

    if (_.blank(list)) {
      return []
    }

    return _.map(list, (email) => {
      if (email.name) {
        return `${email.name} <${email.address}>`
      }
      return email.address
    })
  }

  _hasEmail(email) {
    const parsedEmail = parser.parseOneAddress(email).address

    return _.includes(this._emails, parsedEmail)
  }

  set _value(value) {
    this.collectorTarget.value = value
  }

  get _value() {
    return this.collectorTarget.value
  }

  get _emails() {
    return _.map(this.invitationTargets, (element, i) => {
      const value = this.emailTargets[i].value,
        parsedEmail = parser.parseOneAddress(value)

      return parsedEmail.address
    })
  }

  get _bulkFormConroller() {
    return h.getController(this.element, 'invitations--new--bulk-email-form')
  }
}
