import { Controller } from 'stimulus'
import GoogleAnalytics from 'lib/google_analytics'

export default class RedirectConfirmationController extends Controller {
  redirect(event) {
    const url = event.currentTarget.dataset.url

    GoogleAnalytics.send({
      category: 'externalUrl',
      action: 'click',
      label: url
    })

    SecuricyApp.window.open(url, '_blank')
  }
}
