import ToggleEditController from '../../toggle_edit_controller'
import * as h from 'lib/helpers'

export default class Settings_SingleSignOn_RequireSsoControler extends ToggleEditController {
  static targets = ['form', 'radioButton'].concat(ToggleEditController.targets)

  submit() {
    this.formTarget.submit()
  }

  submitOrDisplayConfirmation() {
    if (this._selectedValue === null || this._selectedValue === 'disabled') {
      this.submit()

      return
    }

    h.dispatchCustomEvent(this.element, 'Settings_SingleSignOn_RequireSsoControler_showConfirmation')
  }

  get _selectedValue() {
    const selectedRadioButton = this.radioButtonTargets.find((radioButton) => {
      return radioButton.checked
    })

    if (selectedRadioButton === null) {
      return null
    }

    return selectedRadioButton.value
  }
}
