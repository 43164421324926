
export default class GoogleAnalytics {
  static FAKE_DATA_LAYER = []

  static send(options) {
    return new GoogleAnalytics(options).run()
  }

  constructor({type = 'event', ... options} = {}) {
    this.type = type
    this.options = options
  }

  run() {
    return this._actionMethod()
  }

  _action_event() {
    return this.dataLayer.push({
      event: 'GAEvent',
      gaEventCategory: this.options.category,
      gaEventAction: this.options.action,
      gaEventLabel: this.options.label,
      gaEventValue: this.options.value,
      gaEventNonInteraction: this.options.nonInteraction
    })
  }

  get dataLayer() {
    if (window.dataLayer) {
      return window.dataLayer
    }

    return this.constructor.FAKE_DATA_LAYER
  }

  get _actionMethod() {
    return this[`_action_${this.type}`] || function () { /* no-op */ }
  }
}
