
import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class Groups_Projects_Edit_TaskCollectorController extends Controller {
  static targets = ['counter', 'collector']

  connect() {
    const dataStore = this._dataStoreController

    if ('preselectedTaskIds' in this.element.dataset) {
      dataStore.set(this._dataStorePath, h.parseData(this.element.dataset.preselectedTaskIds))
    }

    dataStore.registerObserver(this._dataStorePath, this)
  }

  updateState() {
    const values = this._selectedTasks

    this.counterTargets.forEach((target) => {
      target.innerHTML = values.length
    })

    this.collectorTargets.forEach((target) => {
      target.dataset.checked = values.length > 0
    })
  }

  get _selectedTasks() {
    let values = this._dataStoreController.get(this._dataStorePath)

    if (_.blank(values)) {
      values = []
    }

    return values
  }

  get _dataStorePath() {
    return `projects.${this.element.dataset.projectId}.selectedTasks`
  }

  get _dataStoreController() {
    return h.getParentController(this.element, 'data-store')
  }
}
