
import { Controller } from 'stimulus'
import * as h from 'lib/helpers'
import JSONEditor from '@json-editor/json-editor'

export default class JSONFormEditorFieldController extends Controller {
  static targets = ['field', 'fieldType', 'textArea']

  initialize() {
    this._loadJsonEditor()

    this.$element = $(this.element)
    this.options = h.parseData(this.data.get('options')) || {}
    this._buildJsonEditor()
  }

  changeFieldType() {
    this._buildJsonEditor()
  }

  _loadJsonEditor() {
    _.assign(JSONEditor.defaults.languages.en, {
      button_delete_row_title_short: '⤬',
      button_move_down_title: '↓',
      button_move_up_title: '↑',
      button_delete_all_title: '⤬ All',
      button_delete_last_title: '⤬ Last {{0}}',
      button_add_row_title: '+ {{0}}'
    })
  }

  _buildJsonEditor() {
    if (this.editor) {
      this.editor.destroy()
    }

    this.editor = new JSONEditor(this.fieldTarget, {
      'theme': 'bootstrap3',

      // Enable fetching schemas via ajax
      'ajax': true,

      // The schema for the editor
      'schema': this.options.schemas[this._fieldType],
      'startval': this._value,
      'no_additional_properties': true,
      'required_by_default': true,
      'disable_collapse': true,
      'disable_edit_json': true,
      'disable_properties': true
    })

    this.editor.on('ready', () => {
      this.editor.on('change', () => {
        this._changeEvent()
      })
    })
  }

  _changeEvent() {
    this.textAreaTarget.value = this._editorValue
  }

  get _value() {
    if (_.present(this.textAreaTarget.value)) {
      return JSON.parse(this.textAreaTarget.value)
    }
  }

  get _editorValue() {
    if (this.editor.ready) {
      return JSON.stringify(this.editor.getValue())
    }
  }

  get _fieldType() {
    if (_.present(this.fieldTypeTarget.value)) {
      return this.fieldTypeTarget.value
    }

    return this.fieldTypeTarget.options[0].value
  }
}
