
import { Controller } from 'stimulus'

export default class CollapseDropdownController extends Controller {
  static targets = ['container', 'button', 'closeButton']

  connect() {
    $(this.containerTarget).collapse({
      toggle: false
    })
  }

  click(event) {
    if (_.any(this.closeButtonTargets, (target) => target.contains(event.target))) {
      return this.close()
    }

    if (this.containerTarget.contains(event.target)) {
      return event.stopPropagation()
    }

    if (this.buttonTarget.contains(event.target)) {
      return this._toggle()
    }

    this.close()
  }

  keydown(event) {
    if (event.key === 'Escape') {
      this.close()
    }
  }

  _open() {
    if (!this._opened) {
      this.containerTarget.dispatchEvent(new CustomEvent('collapseDropdown_open', {
        bubbles: true,
        cancelable: true
      }))
    }

    $(this.containerTarget).collapse('show')
  }

  close() {
    if (this._opened) {
      this.containerTarget.dispatchEvent(new CustomEvent('collapseDropdown_close', {
        bubbles: true,
        cancelable: true
      }))
    }

    $(this.containerTarget).collapse('hide')
  }

  _toggle() {
    if (this._opened) {
      this.close()
    } else {
      this._open()
    }
  }

  get _opened() {
    return this.containerTarget.classList.contains('in')
  }
}
