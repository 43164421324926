import { Controller } from 'stimulus'
import * as h from 'lib/helpers'
import parser from 'email-addresses'

export default class Invitations_New_BulkEmailFormController extends Controller {
  static targets = ['form', 'errorMessage', 'addButton']

  beforeSubmit() {
    this._hideErrorMessages()
  }

  afterSubmit() {
    const fetchFormController = h.getController(this.formTarget, 'fetch-form'),
      slideoutController = h.getParentController(this.formTarget, 'slide-out-panel'),
      response = fetchFormController.lastResponse

    response.json().then((json) => {
      if (_.blank(json.error_message)) {
        slideoutController.close()
        h.setWindowLocation(SecuricyApp.window.location.href)
        return
      }

      this._showErrorMessage(json.error_message)
      this._showErrors(json.errors)
    })
  }

  validateSingle(event) {
    this._validation.call(event.currentTarget, parser.parseOneAddress, this)
  }

  validateBulk(event) {
    this._validation.call(event.currentTarget, parser.parseAddressList, this)
  }

  _validation(parseMethod, controller) {
    if (_.present(parseMethod(this.value))) {
      this.setCustomValidity('')
      controller.enableAddButton()
    } else {
      this.setCustomValidity('Email addresses must be formatted correctly.')
      controller.disableAddButton()
    }
  }

  disableAddButton() {
    $(this.addButtonTarget)
      .prop('disabled', true)
      .addClass('u-text-shade-2')
  }

  enableAddButton() {
    $(this.addButtonTarget)
      .prop('disabled', false)
      .removeClass('u-text-shade-2')
  }

  _showErrorMessage(message) {
    if (_.present(message)) {
      message = `<i class='material-icons notice-icon'>error_outline</i> ${message}`

      this.errorMessageTarget.innerHTML = message
      $(this.errorMessageTarget).slideDown()
    }
  }

  _showErrors(errors) {
    const inputElements = $('input.email')
    let errorContainer

    _.forOwn(errors, (error, email) => {
      errorContainer = _.find(inputElements, (input) => {
        return input.value === email
      }).closest('.invited-user')
        .querySelector('.error-message')

      errorContainer.innerHTML = error
      $(errorContainer).slideDown()
    })
  }


  _hideErrorMessages() {
    this.errorMessageTarget.style.display = 'none'
    $('.invited-user .error-message').html('').hide()
  }
}
