
import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class CustomEventController extends Controller {
  emit(event) {
    const customEvent = event.target.dataset.customEvent
    let targets = []

    if (_.present(event.target.dataset.customEventTarget)) {
      event.target.dataset.customEventTarget.split(' ').forEach((target) => {
        targets = targets.concat(Array.from(SecuricyApp.document.querySelectorAll(target)))
      })
    } else {
      targets.push(this.element)
    }

    if (_.blank(customEvent)) {
      throw 'missing customEvent type'
    }

    if (_.blank(_.compact(targets))) {
      throw 'invalid customEvent target'
    }

    targets.forEach((target) => {
      h.dispatchCustomEvent(target, customEvent)
    })
  }
}
