import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class NotificationFeedController extends Controller {
  static targets = ['counter', 'container', 'notification']

  connect() {
    this._fetchCount()
    this._loaded = false

    this._notificationFeedChannel = SecuricyApp.cable.subscriptions.create('NotificationFeedChannel', {
      received: () => this._fetchContent()
    })
  }

  disconnect() {
    this._notificationFeedChannel.unsubscribe()
  }

  load() {
    if (!this.loaded) {
      this._fetchContent()
    }
  }

  clear() {
    h.fetch(Routes.notificationsPath(), {
      method: 'PATCH'
    }).then((response) => {
      if (response.ok) {
        response.text().then((html) => {
          this._fadeReplace(html)
          this._updateCounters('')
        })
      }
    })
  }

  _fetchCount() {
    if (this.hasCounterTarget) {
      h.fetch(Routes.countNotificationsPath()).then((response) => {
        if (response.ok) {
          response.text().then((count) => {
            this._updateCounters(count)
          })
        }
      })
    }
  }

  _fetchContent() {
    h.fetch(Routes.notificationsPath()).then((response) => {
      if (response.ok) {
        response.text().then((html) => {
          this.containerTarget.innerHTML = html
          this._updateCounters(this._count)
        })

        this._loaded = true
      }
    })
  }

  get _count() {
    const count = this.notificationTargets.length

    if (count > 0) {
      return count
    }

    return ''
  }

  get loaded() {
    return this._loaded
  }

  _updateCounters(count) {
    this.counterTargets.forEach((counter) => {
      counter.innerHTML = count
    })
  }

  _fadeReplace(html) {
    $(this.containerTarget).hide().html(html).fadeIn('slow')
  }
}
