import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class TabController extends Controller {
  static targets = ['tab', 'content', 'placeholder']

  connect() {
    this._initializeTab()
  }

  clickTab(event) {
    const tab = event.currentTarget

    if (tab === this._currentTab) {
      return
    }

    this.showPlaceholder()
    this._setCurrentTab(tab)
  }

  showPlaceholder() {
    if (this.hasPlaceholderTarget) {
      this.contentTarget.innerHTML = this.placeholderTarget.innerHTML
    }
  }

  refreshCurrentTab() {
    this._setCurrentTab(this._currentTab)
  }

  _initializeTab() {
    this._setCurrentTab(this._initialTab)
  }

  _callSuccessAction(element) {
    this._callAction(element, 'successAction')
  }

  _callErrorAction(element) {
    this._callAction(element, 'errorAction')
  }

  _callAction(element, fetchAction) {
    if (!element.dataset[fetchAction]) {
      return
    }

    h.executeExternalAction(element.dataset[fetchAction], element)
  }

  _switchTab(tab, html) {
    $(this.contentTarget).html(html)
    $(this.tabTargets).removeClass(this._selectedClass)

    $(tab).addClass(this._selectedClass)
  }

  get _currentTab() {
    return this.__currentTab
  }

  get _selectedClass() {
    return this.data.get('selectedClass') || 'selected'
  }

  get _initialTab() {
    const tabName = this.data.get('initialTab')
    let initialTab

    if (_.present(tabName)) {
      initialTab = _.find(this.tabTargets, (tab) => tab.dataset.tabName === tabName)
    }

    return  initialTab || this.tabTargets[0]
  }

  async _setCurrentTab(tab) {
    if (!tab) {
      return
    }

    return h.fetch(tab.dataset.url)
      .then((response) => {
        if (response.ok) {
          this.__currentTab = tab

          this._callSuccessAction(tab)

          response.clone().text().then((html) => {
            this._switchTab(tab, html)
          })

          return response
        }

        throw response
      })

      .catch(() => {
        this._callErrorAction(tab)
        this._errorTab(tab)
      })
  }

  async _errorTab(tab) {
    if (!tab) {
      return
    }

    return h.fetch(Routes.tabErrorPath())
      .then((response) => {
        if (response.ok) {
          response.clone().text().then((html) => {
            this._switchTab(tab, html)
          })

          return response
        }
      })
  }
}
