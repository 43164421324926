
import { Controller } from 'stimulus'
import EasyMDE from 'easymde'
import CodeMirror from 'codemirror'
import * as h from 'lib/helpers'

export default class MarkdownEditorFieldController extends Controller {
  static targets = ['textArea', 'previewConfiguration', 'previewEngine', 'configurator']

  initialize() {
    this._initializeInlineAttachments()

    this.renderMarkdown = _.debounce((markdown) => this._realRenderMarkdown(markdown), 500)
  }

  connect() {
    this._subscribeToMarkdownPreview()
    this._buildEasyMdeEditor()
    this._attachInlineAttachments()
    this._initializeConfigurationHints()
  }

  disconnect() {
    if (this._markdownPreviewChannel) {
      this._markdownPreviewChannel.unsubscribe()
    }
  }

  _generateHints() {
    let params = {}

    if (!_.blank(this.editor.codemirror.state.configurator_id)) {
      params = { configurator_id: this.editor.codemirror.state.configurator_id }
    }

    if (this._hints) {
      this._showHints()

      return
    }

    h.fetch(Routes.keysApiV1ConfiguratorsPath(params), {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          this._hints = json.keys
          this._showHints()
        })
      }
    })
  }

  _showHints() {
    /*eslint-disable babel/new-cap */
    CodeMirror.showHint(this.editor.codemirror, (cm) => {
      let list = []

      list = list.concat(this._hints)

      return {
        list: list,
        from: CodeMirror.Pos(cm.getCursor().line, cm.getCursor().start),
        to: CodeMirror.Pos(cm.getCursor().line, cm.getCursor().ch)
      }
    })
    /*eslint-enable babel/new-cap */
  }

  _initializeConfigurationHints() {
    require('codemirror/addon/hint/show-hint')
    require('codemirror/addon/hint/javascript-hint')

    this.editor.codemirror.setOption('extraKeys', {
      'Cmd-H': () => {
        this._generateHints()
      },
      'Ctrl-H': () => {
        this._generateHints()
      }
    })
  }

  _initializeInlineAttachments() {
    if (SecuricyApp.window.inlineAttachments) {
      return
    }

    require('inline-attachment/src/inline-attachment')
    require('inline-attachment/src/codemirror-4.inline-attachment')
  }

  _subscribeToMarkdownPreview() {
    this._markdownPreviewChannel = SecuricyApp.cable.subscriptions.create({
      channel: 'MarkdownPreviewChannel',
      editor_id: h.uuid()
    }, {
      received: (data) => this.updatePreview(data)
    })
  }

  _buildEasyMdeEditor() {
    if (!this.hasTextAreaTarget) {
      return
    }

    this.editor = new EasyMDE({
      element: this.textAreaTarget,
      forceSync: true,
      previewRender: (markdown) => this._previewRender(markdown),
      toolbar: this._defaultToolbar
    })

    this._buildToolbar()
  }

  _attachInlineAttachments() {
    const that = this

    SecuricyApp.window.inlineAttachment.editors.codemirror4.attach(this.editor.codemirror, {
      allowedTypes: this._options.allowed_types,
      uploadUrl: Routes.organizationAttachmentsPath(),
      extraHeaders: { 'X-CSRF-Token': h.getCsrfToken() },
      onFileUploadResponse: function (response) {
        return that.handleFileUploadResponse(this, response)
      }
    })
  }

  _buildToolbar() {
    this._buildPreviewConfiguration()
    this._buildGuideButton()

    this.editor.gui.toolbar.remove()
    this.editor.gui.toolbar = this.editor.createToolbar()
  }

  _buildPreviewConfiguration() {
    if (!this.hasPreviewConfigurationTarget) {
      return
    }

    const previewIndex = _.findIndex(this.editor.toolbar,
      (entry) => entry.name === 'preview')

    this.editor.toolbar.splice(previewIndex, 0, {
      name: 'preview-configuration',
      action: () => this.togglePreviewConfiguration(),
      className: 'fa fa-check-square',
      title: 'Use Preview Configuration'
    })
  }

  _buildGuideButton() {
    const guideButton = _.find(this.editor.toolbar,
      (entry) => entry.name === 'guide')

    guideButton.action = Routes.editingGuidePath()
  }

  handleFileUploadResponse(attachment, response) {
    const result = JSON.parse(response.responseText)

    if (result.url && result.filename && result.content_type) {
      let newValue

      newValue = attachment.settings.urlText
        .replace('[file]', `[${result.filename}]`)
        .replace(attachment.filenameTag, result.url)

      if (!_.includes(this._options.allowed_image_types, result.content_type)) {
        newValue = newValue.replace(/^!/, '')
      }

      attachment.editor.setValue(attachment.editor.getValue().replace(attachment.lastValue, newValue))
      attachment.settings.onFileUploaded(result.filename)
    }

    return false
  }

  _previewRender(markdown) {
    this.renderMarkdown(markdown)

    return this.html || 'Generating preview...'
  }

  updatePreview(html) {
    this.html = html

    _.each(this._$previews, (element) => {
      element.innerHTML = html
    })
  }

  _realRenderMarkdown(markdown) {
    const options = {
      markdown: markdown
    }
    let method

    if (this._isPreviewConfigurationActive) {
      options.configuration = this._previewConfiguration
      options.engines = this._previewEngines
      method = 'render_with_preview'
    } else {
      options.configuration = this._objectConfiguration
      method = 'render'
    }

    this._markdownPreviewChannel.perform(method, options)
  }

  _refreshPreviews() {
    this.editor.codemirror.refresh()
  }

  togglePreviewConfiguration() {
    $(this.previewConfigurationTarget).toggle()

    if (!this._jsonViewer.jsonEditor.options.onChangeText) {
      this._loadPreviewConfiguration()

      this._jsonViewer.jsonEditor.options.onChangeText = _.debounce((json) => {
        this._previewConfiguration = json
      }, 1000)
    }

    this._previewConfigurationEnabled = !this._previewConfigurationEnabled

    this._refreshPreviews()
  }

  _loadPreviewConfiguration() {
    if (_.present(this._previewConfiguration)) {
      this._jsonViewer.json = this._previewConfiguration
    } else {
      this._previewConfiguration = this._jsonViewer.json
    }
  }

  get _defaultToolbar() {
    const toolbar = [
      ['bold', 'italic', 'heading'],
      ['quote', 'unordered-list', 'ordered-list', 'table'],
      ['link', 'image']
    ]

    if (this._isInModal) {
      toolbar.push(['preview'])
    } else {
      toolbar.push(['preview', 'side-by-side', 'fullscreen'])
    }

    toolbar.push(['guide'])

    return toolbar.reduce((accum, el) => accum.concat(el, '|'), [])
  }

  get _$previews() {
    return $(this.element).find('.editor-preview, .editor-preview-side')
  }

  get _isPreviewConfigurationActive() {
    return !!this._previewConfigurationEnabled
  }

  get _jsonViewer() {
    return h.getChildController(this.previewConfigurationTarget, 'json-viewer')
  }

  get _previewConfiguration() {
    try {
      return JSON.parse(SecuricyApp.window.localStorage.getItem('__previewConfigurationJSON__'))
    } catch (ignore) {
      /* no-op */
    }
  }

  set _previewConfiguration(json) {
    if (!_.isString(json)) {
      json = JSON.stringify(json)
    }

    SecuricyApp.window.localStorage.setItem('__previewConfigurationJSON__', json)

    this._refreshPreviews()
  }

  get _objectConfiguration() {
    return h.parseData(this.data.get('configuration'))
  }

  get _previewEngines() {
    return this.previewEngineTargets
      .filter((previewEngine) => previewEngine.checked)
      .map((previewEngine) => previewEngine.value)
  }

  get _options() {
    return h.parseData(this.data.get('options'))
  }

  get _isInModal() {
    return $(this.element).parents('.c-full-screen-modal').length !== 0
  }
}
