import { Controller } from 'stimulus'

export default class ConfiguratorController extends Controller {
  connect() {
    _.defer(() => {
      this.setCodeMirrorConfiguratorState(this.element.value)
    })
  }

  change(event) {
    this.setCodeMirrorConfiguratorState(event.currentTarget.value)
  }

  setCodeMirrorConfiguratorState(value) {
    const editor = this._getCodeMirrorInstance()

    if (_.isDefined(editor)) {
      editor.state.configurator_id = value
    }
  }

  _getCodeMirrorInstance() {
    if (!_.isNil(SecuricyApp.document.querySelector('.CodeMirror'))) {
      return SecuricyApp.document.querySelector('.CodeMirror').CodeMirror
    }
  }
}
