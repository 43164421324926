
import * as h from 'lib/helpers'

export default class DefaultFetch {
  static run() {
    return new DefaultFetch(... arguments).run()
  }

  static addFetchObserver(observer) {
    DefaultFetch.OBSERVERS.push(observer)
  }

  static clearFetchObservers() {
    DefaultFetch.OBSERVERS = []
  }

  static OBSERVERS = []

  constructor(input, init = {}) {
    this.input = input

    this.mergedInit = _.merge({
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': h.getCsrfToken(),
        'X-Fetch': true
      }
    }, init)
  }

  run() {
    const request = fetch(this.input, this.mergedInit)
      .then((response) => {
        this.constructor.OBSERVERS.some((observer) => {
          let result

          if (_.isFunction(observer.prototype.observeFetch)) {
            /*eslint-disable babel/new-cap */
            result = new observer(response, request).observeFetch()
            /*eslint-enable babel/new-cap */
          } else {
            result = observer.call(null, response, request)
          }

          return result === false
        })

        return response
      })

    return request
  }
}
