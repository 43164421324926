
import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class FetchController extends Controller {
  fetch(event) {
    const url = event.currentTarget.dataset.fetchUrl,
      element = event.currentTarget || event.target,
      method = (event.currentTarget.dataset.fetchMethod || 'GET').toUpperCase()

    h.fetch(url, {
      method: method,
      headers: {
        'content-type': 'application/json'
      }
    }).then((response) => {
      if (response.ok) {
        this._callSuccessAction(element)

        return
      }

      throw response
    }).catch(() => {
      this._callErrorAction(element)
    })
  }

  _callSuccessAction(element) {
    this._callAction(element, 'fetchSuccessAction')
  }

  _callErrorAction(element) {
    this._callAction(element, 'fetchErrorAction')
  }

  _callAction(element, fetchAction) {
    if (!element.dataset[fetchAction]) {
      return
    }

    h.executeExternalAction(element.dataset[fetchAction], element)
  }
}
