import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class LinkedCheckCirclesController extends Controller {

  connect() {
    const dataStore = this._dataStoreController

    if (this._checked) {
      dataStore.add(this._dataStorePath, this._value)
    }

    dataStore.registerObserver(this._dataStorePath, this)
  }

  toggle() {
    const dataStore = this._dataStoreController

    if (this._checked) {
      dataStore.remove(this._dataStorePath, this._value)
    } else {
      dataStore.add(this._dataStorePath, this._value)
    }
  }

  updateState() {
    const values = this._dataStoreController.get(this._dataStorePath)

    this._checked = _.includes(values, this._value)
  }

  set _checked(value) {
    this.element.dataset.checked = value

    if (_.present(this._input)) {
      this._input.disabled = !value
    }
  }

  get _checked() {
    return h.parseData(this.element.dataset.checked)
  }

  get _dataStorePath() {
    return this.element.dataset.linkedScope
  }

  get _value() {
    const value = _.tryIt(this._input, 'value')

    if (_.present(value)) {
      return value
    }

    return this.element.dataset.linkedValue
  }

  get _input() {
    const input = this.element.querySelector('input[type=hidden]')

    if (_.present(input)) {
      return input
    }
  }

  get _dataStoreController() {
    return h.getParentController(this.element, 'data-store')
  }
}
