import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class ModalController extends Controller {
  static targets = ['body']

  click(event) {
    event.preventDefault()

    return this._loadModalContent(event.target.getAttribute('href'))
  }

  _loadModalContent(url) {
    return h.fetch(url, {
      headers: {
        'X-PJAX': true,
        'Content-Type': 'text/html'
      }
    })
      .then((response) => {
        if (response.ok) {
          response.text().then((html) =>
            this._updateModal(html))
        }
      })
  }

  _updateModal(html) {
    this.bodyTarget.innerHTML = html

    $(this.bodyTarget).modal(this._modalOptions)
  }

  get _modalOptions() {
    return {
      keyboard: true,
      focus: true
    }
  }
}
