
import { Controller } from 'stimulus'
import JSONEditor from 'jsoneditor'
import { parseData } from 'lib/helpers'

export default class JsonViewerController extends Controller {
  static targets = ['container']

  connect() {
    this.jsonEditor = new JSONEditor(
      this.containerTarget, _.defaults({
        mode: 'view'
      }, parseData(this.data.get('options'))),

      parseData(this.data.get('data'))
    )
  }

  get json() {
    return this.jsonEditor.get()
  }

  set json(value) {
    this.jsonEditor.set(value)
  }
}
