import ToggleVisibilityController from 'controllers/toggle_visibility_controller'

export default class NestedToggleSwitchController extends ToggleVisibilityController {
  static targets = ['toggle']

  hide() {
    this.toggleTargets.forEach((toggle) => {
      toggle.querySelectorAll('input[type="checkbox"]').forEach((input) => {
        input.checked = false
      })
    })

    super.hide()
  }
}
