import { Controller } from 'stimulus'

export default class LoadingSpinnerController extends Controller {
  initialize() {
    this.$element = $(this.element)
    this.$container = $(this.data.get('container'))
    this.$body = $(this.application.element).find('body')
  }

  connect() {
    if (_.present(SecuricyApp.loadingSpinner)) {
      return
    }

    this.hide()
    SecuricyApp.loadingSpinner = this
    SecuricyApp.window.addEventListener('beforeunload', () => this.show())
  }

  hide() {
    this.$element.hide()
    this.$container.show()
    this.$body.removeClass('no_scroll')
  }

  show() {
    this.$element.show()
    this.$container.hide()
    this.$body.addClass('no_scroll')
  }
}
