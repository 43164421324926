
import SelectAllController from 'controllers/select_all_controller'
import * as h from 'lib/helpers'

export default class Groups_Projects_Edit_TaskSelectAllController extends SelectAllController {

  toggle() {
    const dataStore = this._dataStoreController

    if (!h.parseData(this.selectAllTarget.dataset.checked)) {
      dataStore.add('selectedProjects', this.data.get('projectId'))
    }

    super.toggle()
  }
}
