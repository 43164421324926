
import BusinessContinuityExport_DynamicSectionController from './dynamic_section_controller'

export default class BusinessContinuityExport_CriticalBusinessFunctionsController extends BusinessContinuityExport_DynamicSectionController {
  static targets = ['priorityIndicator', 'prioritySelector', ...BusinessContinuityExport_DynamicSectionController.targets]

  newPrioritySelected(event) {
    const index = this.prioritySelectorTargets.indexOf(event.currentTarget),
      indicator = this.priorityIndicatorTargets[index]

    indicator.classList.remove('urgent-priority', 'high-priority', 'medium-priority', 'low-priority')
    indicator.classList.add(`${event.target.value}-priority`)
  }
}
