
import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class NestedMenuController extends Controller {
  connect() {
    if (_.present(this.data.get('mobile'))) {
      this.element.addEventListener('nestedMenu_open', (event) => {
        this.hide(event)
      })

      this.element.addEventListener('nestedMenu_close', (event) => {
        this.show(event)
      })
    }
  }

  open() {
    // display the list
    this.element.classList.add('c-nested-menu--open')

    // if there is a parent nav menu controller, tell it to hide()
    h.dispatchCustomEvent(this.element, 'nestedMenu_open')
  }

  close() {
    // collapse the list
    this.element.classList.remove('c-nested-menu--open', 'c-nested-menu--hidden')

    // if there is a parent nav menu controller, tell it to show()
    h.dispatchCustomEvent(this.element, 'nestedMenu_close')
  }

  toggle() {
    if (this.element.classList.contains('c-nested-menu--open')) {
      this.close()
    } else {
      this.open()
    }
  }

  hide(event) {
    if (event.target === this.element) {
      return
    }

    // make the immediate child links invisible
    this.element.classList.add('c-nested-menu--hidden')
    event.stopPropagation()
  }

  show(event) {
    if (event.target === this.element) {
      return
    }

    // make the immediate child links visible
    this.element.classList.remove('c-nested-menu--hidden')
    event.stopPropagation()
  }

  keydown(event) {
    if (event.key === 'Escape') {
      this.element.classList.remove('c-nested-menu--open', 'c-nested-menu--hidden')
    }
  }

  click(event) {
    // ignore if the click occurred inside of an element that has a parent nav-menu controller
    if (this.element.contains(event.target)) {
      return
    }

    // otherwise, close
    this.element.classList.remove('c-nested-menu--open', 'c-nested-menu--hidden')
  }
}
