
import { Controller } from 'stimulus'
import * as Ace from 'ace-builds/src-noconflict/ace'
import 'ace-builds/webpack-resolver'

export default class AceEditorFieldController extends Controller {
  static targets = ['editor', 'textArea']

  initialize() {
    this._buildAceEditor()

    this.editor.getSession().on('change', () => {
      this.textAreaTarget.value = this.editor.getSession().getValue()
    })
  }

  _buildAceEditor() {
    if (!this.hasEditorTarget) {
      return
    }

    this.editor = Ace.edit(this.editorTarget, {
      mode: 'ace/mode/ruby',
      tabSize: 2,
      useSoftTabs: true
    })

    this.editor.session.setValue(this.textAreaTarget.value)
  }
}
