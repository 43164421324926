import { Controller } from 'stimulus'

export default class HambMenuController extends Controller {
  static targets = ['button']

  connect() {
    this._isActive = false
  }

  toggle() {
    $(this.buttonTarget)
      .toggleClass('is-active')

    $(this._megaMenuSelector)
      .toggleClass('is-active')

    this._isActive = !this._isActive
  }

  keydown(event) {
    if (event.key === 'Escape' && this._isActive) {
      this.toggle()
    }
  }

  get _megaMenuSelector() {
    return this.data.get('mega-menu')
  }
}
