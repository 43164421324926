
import { Controller } from 'stimulus'

export default class IntercomController extends Controller {
  hide() {
    $(this.container).hide()
  }

  show() {
    $(this.container).show()
  }

  get container() {
    return SecuricyApp.document.getElementById('intercom-container')
  }
}
