
import { Controller } from 'stimulus'

export default class Projects_Tasks_Show_MarkAsCompletedController extends Controller {
  static targets = ['button', 'completed']

  completed() {
    SecuricyApp.window.dispatchEvent(new CustomEvent('Projects_Tasks_Show_MarkAsCompleted_completed', {
      bubbles: true,
      cancelable: true
    }))

    $(this.buttonTargets).remove()
    $(this.completedTargets).show()
  }
}
