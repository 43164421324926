
import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class Policies_Configuration_DoneController extends Controller {
  static targets = ['container', 'assemblingTemplate', 'doneTemplate']

  performConfiguration(event) {
    event.preventDefault()

    this._renderTemplate('assembling')

    h.fetch(event.currentTarget.getAttribute('action'), {
      body: JSON.stringify($(event.currentTarget).serializeJSON()),
      method: event.currentTarget.method,
      headers: {
        'content-type': 'application/json'
      }
    }).then(() => this._renderTemplate('done'))

    SecuricyApp.fullScreenModal.close()
  }

  _template(name) {
    return this[`${name}TemplateTarget`].innerHTML
  }

  _renderTemplate(name) {
    this.containerTarget.innerHTML = h.renderTemplate(this._template(name))
  }
}
