
import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class Configurator_RequiredCheckboxController extends Controller {
  static targets = ['checkbox', 'warning']

  checkRequired(event) {
    if (!this.hasCheckboxTarget) {
      this._submit()

      return true
    }

    event.preventDefault()

    if (!this._required) {
      this._submit()

      return true
    }

    let checked = false

    this.checkboxTargets.some((checkbox) => {
      if (checkbox.checked) {
        this._submit()
        checked = true

        return true
      }
    })

    if (!checked) {
      this._showWarning()
    }
  }

  _showWarning() {
    if (this.hasWarningTarget) {
      $(this.warningTarget).show()
    }
  }

  _submit() {
    h.callStimulusAction('form', 'configurator--configurator-page', 'submit')
  }

  get _required() {
    return h.parseData(this.element.dataset.required)
  }
}
