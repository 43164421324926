import LinkedCheckCirclesController from 'controllers/linked_check_circles_controller'
import * as h from 'lib/helpers'

export default class Groups_Projects_Edit_ProjectSelectorController extends LinkedCheckCirclesController {

  toggle() {
    const dataStore = this._dataStoreController

    if (h.parseData(this.element.dataset.checked)) {
      dataStore.set(this._taskStorePath, [])
    } else {
      dataStore.set(this._taskStorePath, this._allTaskIds)
    }

    super.toggle()
  }

  get _allTaskIds() {
    return h.parseData(this.element.dataset.taskIds)
  }

  get _taskStorePath() {
    return `projects.${this._value}.selectedTasks`
  }
}
