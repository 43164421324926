import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class Groups_Projects_Edit_FormController extends Controller {
  static targets = ['projectSelector']

  submit(event) {
    event.preventDefault()

    h.fetch(this.element.action, {
      body: JSON.stringify(this._params),
      method: 'PATCH',
      headers: {
        'content-type': 'application/json'
      }
    }).then((response) => {
      if (response.ok) {
        this._successAction()
      } else {
        this._errorAction()
      }
    })
  }

  _successAction() {
    const showController = h.getParentController(this.element, 'groups--show'),
      slideoutController = h.getParentController(this.element, 'slide-out-panel')

    showController.refreshChangeableHtml()

    slideoutController.close()
  }

  _errorAction() {
    const buttonSpinner = h.getParentController(this.element, 'button-spinner')

    buttonSpinner.stopSpinner()
  }

  get _params() {
    const params = $(this.element).serializeJSON()
    let projectId, taskIds

    params.project_tasks = {}

    this.projectSelectorTargets.forEach((selector) => {
      projectId = selector.value

      taskIds = this._dataStore.get(`projects.${projectId}.selectedTasks`)

      if (_.blank(taskIds)) {
        taskIds = []
      }

      params.project_tasks[projectId] = taskIds
    })

    return params
  }

  get _dataStore() {
    return h.getParentController(this.element, 'data-store')
  }
}
