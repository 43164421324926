
import { Controller } from 'stimulus'

export default class BulkCheckboxController extends Controller {
  static targets = ['checkbox', 'actionForm', 'button']

  toggle() {
    this._updateButtons()
  }

  clickButton() {
    $(this.actionFormTargets).html(this._checkedItems.map((checkbox) =>
      `<input type="hidden" name="bulk_ids[]" value="${checkbox.value}" />`))
  }

  _updateButtons() {
    $(this.buttonTargets)
      .toggleClass('no-bulk-checkbox-checked', !this._hasCheckedItems)
  }

  get _checkedItems() {
    return _.filter(this.checkboxTargets, 'checked')
  }

  get _hasCheckedItems() {
    return this._checkedItems.length
  }
}
