import { Controller } from 'stimulus'

export default class ToggleEditController extends Controller {
  static targets = ['view', 'edit']

  click(event) {
    if (this._opened) {
      this.close()
    } else {
      this.open(event)
    }
  }

  open(event) {
    $(this.viewTargets).hide()
    $(this.editTargets).hide()

    this._editTargets(event.target.dataset.toggleEditTarget).show()
  }

  close() {
    $(this.viewTargets).show()
    $(this.editTargets).hide()
  }

  get _opened() {
    return $(this.editTargets).filter(':visible').length !== 0
  }

  _editTargets(selector) {
    if (_.present(selector)) {
      return $(this.editTargets).filter(selector)
    }

    return $(this.editTargets)
  }
}
