
import { Controller } from 'stimulus'

export default class ReportingCenter_ExportReportController extends Controller {
  static targets = ['form', 'toggleSwitch', 'error', 'errorText']

  performValidations(event) {
    event.preventDefault()
    const submissionErrors = this._submissionErrors()

    if (_.present(submissionErrors)) {
      this._displaySubmissionErrors(submissionErrors)

      return
    }

    $(this.errorTarget).hide()
    this.formTarget.submit()
  }

  hideErrorMessage() {
    $(this.errorTarget).hide()
  }

  _displaySubmissionErrors(submissionErrors) {
    SecuricyApp.window.scrollTo(0, 0)

    $(this.errorTarget).show()
    $(this.errorTextTarget).empty()

    submissionErrors.forEach((errorText) => {
      this.errorTextTarget.innerHTML += errorText + '<br>'
    })
  }

  _submissionErrors() {
    const errors = []

    if (this.hasToggleSwitchTarget && !_.some(this.toggleSwitchTargets, 'checked')) {
      errors.push('You must select at least one export option.')
    }

    return errors
  }
}
