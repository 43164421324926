
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import StimulusReflex from 'stimulus_reflex'

import DefaultFetch from 'lib/default_fetch'
import GoogleAnalyticsFetchObserver from 'lib/google_analytics_fetch_observer'
import PaywallFetchObserver from 'lib/paywall_fetch_observer'
import RedirectToSelectOrganizationFetchObserver from 'lib/redirect_to_select_organization_fetch_observer'
import SessionTimeoutFetchObserver from 'lib/session_timeout_fetch_observer'

SecuricyApp.cable = ActionCable.createConsumer()
SecuricyApp.application = Application.start()
SecuricyApp.application.load(definitionsFromContext(require.context('controllers', true, /\.js$/)))
StimulusReflex.initialize(SecuricyApp.application, SecuricyApp.cable)

DefaultFetch.addFetchObserver(GoogleAnalyticsFetchObserver)
DefaultFetch.addFetchObserver(PaywallFetchObserver)
DefaultFetch.addFetchObserver(RedirectToSelectOrganizationFetchObserver)
DefaultFetch.addFetchObserver(SessionTimeoutFetchObserver)
