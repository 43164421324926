
import { Controller } from 'stimulus'
import Cookies from 'js-cookie'

export default class SessionTimezoneController extends Controller {
  connect() {
    if (_.isUndefined(Cookies.get('user_timezone'))) {
      Cookies.set('user_timezone', moment.tz.guess())
    }
  }
}
