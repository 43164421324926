
import { Controller } from 'stimulus'
import Mustache from 'mustache'
import * as h from 'lib/helpers'

export default class BusinessContinuityExport_DynamicSectionController extends Controller {
  static targets = ['container', 'counter', 'template']

  addSection() {
    const counter = Number(h.parseData(this.counterTarget.value)),
      $newSection = $(Mustache.render(this._sectionTemplateCompiled, {
        index: counter,
        count: counter + 1
      }))

    $newSection.find('[disabled]').removeAttr('disabled')

    $(this.containerTarget).append($newSection)

    this.counterTarget.value = counter + 1
  }

  get _sectionTemplateCompiled() {
    if (!this.__sectionTemplateCompiled) {
      this.__sectionTemplateCompiled = this.templateTarget.innerHTML

      Mustache.parse(this.__sectionTemplateCompiled)
    }

    return this.__sectionTemplateCompiled
  }
}
