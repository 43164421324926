
import { Controller } from 'stimulus'
import { parseData } from 'lib/helpers'

export default class FreemiumOverlayController extends Controller {
  static targets = ['button', 'modal', 'modalGraphic']

  _toggleMode() {
    if (this._modalOpen) {
      this.hideModal()
    } else {
      this.showModal()
    }
  }

  showModal() {
    $(this.buttonTarget)
      .addClass('u-fade-up--out')

    $(this.modalTarget)
      .addClass('u-fade-up')
      .show()

    this.data.set('modalOpen', true)

    setTimeout(() => {
      $(this.modalTarget).removeClass('u-fade-up')
      $(this.buttonTarget)
        .removeClass('u-fade-up--out')
        .hide()
    }, 550)
  }

  hideModal() {
    $(this.modalTarget)
      .addClass('u-fade-down')

    $(this.buttonTarget).stop().show()

    this.data.set('modalOpen', false)

    setTimeout(() => {
      $(this.modalTarget)
        .removeClass('u-fade-down')
        .hide()
    }, 550)
  }

  get _modalOpen() {
    return parseData(this.data.get('modalOpen'))
  }
}
