import { Controller } from 'stimulus'

export default class StickyHeaderController extends Controller {
  static targets = ['content']

  initialize() {
    this._atTop = _.throttle(this._atTop, 100, { trailing: false })
    this._scrolledUp = _.throttle(this._scrolledUp, 100, { trailing: false })
    this._scrolledDown = _.throttle(this._scrolledDown, 100, { trailing: false })
  }

  connect() {
    this.__scrollTop = 0
    this.__lastScrollTop = 0
    this.__elementHeight = this.contentTarget.offsetHeight
  }

  scroll() {
    this._scrollTop = $(document).scrollTop()

    if (this._scrollTop < this.__elementHeight / 2) {
      this._atTop()
    } else if (this._scrollDirection < 0) {
      this._scrolledUp()
    } else if (this._scrollDirection > 0) {
      this._scrolledDown()
    }
  }

  _atTop() {
    this._collapsed = false
    this._sticky = false
    this._hidden = false
  }

  _scrolledUp() {
    this._hidden = false
    this._collapsed = true
    this._sticky = true
  }

  _scrolledDown() {
    this._hidden = this._scrollTop > this.__elementHeight
    this._collapsed = true
    this._sticky = true
  }

  get _scrollDirection() {
    return this._scrollTop - this.__lastScrollTop
  }

  set _scrollTop(value) {
    this.__lastScrollTop = this.__scrollTop
    this.__scrollTop = value
  }

  get _scrollTop() {
    return this.__scrollTop
  }

  set _collapsed(value) {
    this.element.classList.toggle('c-sticky-header--collapsed', value)
  }

  set _hidden(value) {
    this.element.classList.toggle('c-sticky-header--hidden', value)
  }

  set _sticky(value) {
    this.element.classList.toggle('c-sticky-header--sticky', value)
  }
}
