
import { Controller } from 'stimulus'

export default class Configurator_PendingInvitedUserController extends Controller {
  static targets = ['email', 'otherText']

  selectInput(event) {
    this.emailTarget.value = event.currentTarget.dataset.email
    this.emailTarget.disabled = false
    this.otherTextTarget.disabled = true
  }
}
