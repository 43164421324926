import { Controller } from 'stimulus'
import 'jquery-visible'
import * as h from 'lib/helpers'

export default class LazyLoadController extends Controller {
  static targets = ['visible']

  connect() {
    this.isVisible = false

    $(document).scroll(() => {
      this.targetVisible()
    })
  }

  targetVisible() {
    if ($(this.visibleTarget).visible()) {
      if (!this.isVisible) {
        this.isVisible = true
        this._visibleCallback()
      }
    } else {
      this.isVisible = false
    }
  }

  _visibleCallback() {
    h.executeExternalAction(this.visibleTarget.dataset.visibleCallback, this.visibleTarget)
  }
}
