import { Controller } from 'stimulus'

export default class FileAttachmentController extends Controller {
  static targets = [
    'input', 'hiddenInput',
    'filename', 'submitNote',
    'indicator'
  ]

  static STATES = {
    blank: 'c-attachment-field--blank',
    selected: 'c-attachment-field--selected',
    uploading: 'c-attachment-field--uploading',
    complete: 'c-attachment-field--complete',
    error: 'c-attachment-field--error'
  }

  connect() {
    this.inputTarget.setAttribute('data-direct-upload-url', this._url)
  }

  select() {
    this._state = 'selected'
    this._note = 'File will upload when form submits'
    this._filename = this._file.name
  }

  error(event) {
    this._state = 'error'
    this._note = event.detail.error
  }

  complete() {
    this._state = 'complete'
  }

  progress(event) {
    this._state = 'uploading'
    this.indicatorTarget.style.width = `${event.detail.progress}%`
  }

  get _file() {
    return this.inputTarget.files[0]
  }

  get _url() {
    return Routes.railsDirectUploadsPath()
  }

  get _form() {
    return this.inputTarget.form
  }

  set _state(state) {
    this.element.classList.remove(... _.values(FileAttachmentController.STATES))
    this.element.classList.add(FileAttachmentController.STATES[state])
  }

  set _note(note) {
    this.submitNoteTarget.innerHTML = note
  }

  set _filename(filename) {
    this.filenameTarget.innerHTML = `File selected: ${filename}`
  }
}
