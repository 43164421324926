import { Controller } from 'stimulus'
import GoogleAnalytics from 'lib/google_analytics'

export default class DownloadFileController extends Controller {
  download(event) {
    const url = this.element.getAttribute('href')

    GoogleAnalytics.send({
      category: this._category,
      action: event.type,
      label: url
    })
  }

  get _category() {
    return this.data.get('category') || 'downloadFile'
  }
}
