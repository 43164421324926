
import ExternalAction from 'lib/stimulus/external_action'
import DefaultFetch from 'lib/default_fetch'
import Mustache from 'mustache'

export function setWindowLocation(location) {
  SecuricyApp.window.location = location
}

export function openLocation(location) {
  SecuricyApp.window.open(location)
}

export function isWindowLocation(url) {
  return SecuricyApp.window.location.toString() === _.interpret(url)
}

export function log() {
  if (SecuricyApp.window && SecuricyApp.window.console && SecuricyApp.window.console.log) {
    SecuricyApp.window.console.log.apply(SecuricyApp.window.console, arguments)
  }
}

export function getController(selector, identifier) {
  return SecuricyApp.application.getControllerForElementAndIdentifier(
    $(selector)[0],
    identifier
  )
}

export function getChildController(selector, identifier) {
  return SecuricyApp.application.getControllerForElementAndIdentifier(
    $(selector).closestChild(`[data-controller~=${identifier}]`)[0],
    identifier
  )
}

export function getParentController(selector, identifier) {
  if ($(selector).is(`[data-controller~=${identifier}]`)) {
    return getController(selector, identifier)
  }

  return SecuricyApp.application.getControllerForElementAndIdentifier(
    $(selector).parents(`[data-controller~=${identifier}]`)[0],
    identifier
  )
}

export function getCsrfParam() {
  return $('meta[name=csrf-param]').attr('content')
}

export function getCsrfToken() {
  return $('meta[name=csrf-token]').attr('content')
}

export function parseData(data) {
  if (data === 'true') {
    return true
  }

  if (data === 'false') {
    return false
  }

  if (data === 'null') {
    return null
  }

  // Only convert to a number if it doesn't change the string
  if (data === +data + '') {
    return +data
  }

  if (/^(?:\{[\w\W]*\}|\[[\w\W]*\])$/.test(data)) {
    try {
      return JSON.parse(data)
    } catch (ignore) {
      // no-op
    }
  }

  return data
}

export function executeExternalAction(value, element) {
  value.split(/\s+/).forEach((actionDescriptor) => {
    const externalAction = new ExternalAction(actionDescriptor, element)

    externalAction.execute()
  })
}

export function callStimulusAction(element, identifier, action) {
  return _.tryIt(getController(element, identifier), action)
}

export function callStimulusActionOnParent(element, identifier, action) {
  return _.tryIt(getParentController(element, identifier), action)
}

export function fetch() {
  return DefaultFetch.run(... arguments)
}

export function renderTemplate(template, data) {
  Mustache.parse(template)

  return Mustache.render(template, data)
}

export function randomHexString(length = 16) {
  return Array.from(crypto.getRandomValues(new Uint8Array(length / 2)))
    .map((c) => ('0' + c.toString(16)).substr(-2))
    .join('')
}

export function windowCallback(callback) {
  const callbackName = `callback_${randomHexString()}`

  SecuricyApp.window[callbackName] = function () {
    try {
      return callback()
    } finally {
      delete SecuricyApp.window[callbackName]
    }
  }

  return callbackName
}

export function dispatchCustomEvent(target, name, detail = {}) {
  target.dispatchEvent(new CustomEvent(name, {
    bubbles: true,
    cancelable: true,
    detail
  }))
}

export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    /*eslint-disable no-bitwise */
    const r = Math.random() * 16 | 0,
      v = c === 'x' ?
        r :
        r & 0x3 | 0x8

    return v.toString(16)
    /*eslint-enable no-bitwise */
  })
}
