
import { Controller } from 'stimulus'
import Mustache from 'mustache'
import * as h from 'lib/helpers'

export default class Configurator_DelegationOfAuthority_RoleController extends Controller {
  static targets = ['role', 'delegates', 'addButton', 'roleInput', 'inputsContainer', 'input']

  statusButton() {
    $(this.addButtonTarget).toggleClass('is-disabled', this.roleInputTarget.value === '')
  }

  delegationOfAuthorityController() {
    return h.getController('#delegation-of-authority', 'configurator--delegation-of-authority')
  }

  updateRole() {
    const role = this.roleInputTarget.value

    if (role === '') {
      return
    }

    _.each(this.roleTargets, (element) => {
      $(element).html(role)
    })

    $(this.delegatesTarget).show()

    return false
  }

  addDelegate() {
    const html = Mustache.render(this._delegateTemplateCompiled, {
      role_index: this.inputsContainerTarget.dataset.roleIndex,
      delegate_index: this.inputTargets.length,
      delegate_count: this.inputTargets.length + 1
    })

    $(this.inputsContainerTarget).append(html)
  }

  get _delegateTemplateCompiled() {
    return this.delegationOfAuthorityController().delegateTemplateCompiled
  }
}
