
import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class PseudoLinkController extends Controller {
  click(event) {
    const path = this._path(event.currentTarget),
      target = event.currentTarget.dataset.linkTarget


    if (_.blank(path)) {
      return
    }

    if (target === 'new') {
      h.openLocation(path)
    } else {
      h.setWindowLocation(path)
    }
  }

  _path(target) {
    let href = target.getAttribute('href')

    if (_.blank(href)) {
      href = target.dataset.href
    }

    return href
  }
}
