
import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class Settings_MultiFactorAuthentication_VerificationController extends Controller {
  static targets = ['digit', 'container', 'enableTemplate', 'backupCodesTemplate']

  connect() {
    this.digitTargets[0].focus()
  }

  keydown(event) {
    event.preventDefault()

    if (event.key === 'Backspace') {
      this._goToPreviousDigit(event)

      return
    }

    if (!_.isDigit(event.key)) {
      return
    }

    event.currentTarget.value = event.key
    this._goToNextDigit(event)

    if (this._allDigitsFilled) {
      this._verify()
    }
  }

  enable() {
    h.fetch(Routes.accountMultiFactorAuthenticationFinishSettingsPath(), {
      method: 'PATCH'
    }).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          this.containerTarget.innerHTML = h.renderTemplate(this.backupCodesTemplateTarget.innerHTML, json)
        })
      }
    })
  }

  done() {
    SecuricyApp.window.location = Routes.settingsPath({ tab: 'account' })
  }

  _goToPreviousDigit(event) {
    const previousDigit = this.digitTargets[this.digitTargets.indexOf(event.currentTarget) - 1]

    if (previousDigit) {
      previousDigit.value = null

      previousDigit.focus()
    }
  }

  _goToNextDigit(event) {
    const nextDigit = this.digitTargets[this.digitTargets.indexOf(event.currentTarget) + 1]

    event.currentTarget.value = event.key

    if (nextDigit) {
      nextDigit.focus()
    }
  }

  _disableDigits() {
    this.digitTargets.forEach((digit) => { digit.disabled = true })
  }

  _enableDigits() {
    this.digitTargets.forEach((digit) => { digit.disabled = false })
  }

  _clearDigits() {
    this.digitTargets.forEach((digit) => { digit.value = null })
  }

  _verify() {
    this._disableDigits()

    h.fetch(Routes.accountMultiFactorAuthenticationPerformVerificationSettingsPath(), {
      method: 'POST',
      body: JSON.stringify({
        code: this._code
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if (response.ok) {
        this._verified()

        return
      }

      throw response
    }).catch(() => {
      this._notVerified()
    })
  }

  _verified() {
    this.containerTarget.innerHTML = h.renderTemplate(this.enableTemplateTarget.innerHTML)
  }

  _notVerified() {
    this._clearDigits()
    this._enableDigits()

    this.digitTargets[0].focus()
  }

  get _allDigitsFilled() {
    return _.all(_.map(this.digitTargets, 'value'), _.present)
  }

  get _code() {
    return _.map(this.digitTargets, 'value').join('')
  }
}
