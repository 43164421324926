
import { Controller } from 'stimulus'
import { renderTemplate } from 'lib/helpers'
import Tabulator from 'tabulator-tables'

export default class Configurator_TabularDataController extends Controller {
  static targets = ['container', 'input']

  static MIN_COLUMNS = 12

  connect() {
    this.tabulator = new Tabulator(this.containerTarget, {
      columns: this._columnData,
      data: this._data,
      dataEdited: (data) => this.updateData(data),
      layout: 'fitColumns'
    })

    this.tabulator.redraw(true)
  }

  addRow() {
    this.tabulator.addRow({}, false)
  }

  updateData(data) {
    this.inputTarget.value = JSON.stringify(_.filter(data, (row) => {
      return _.any(_.values(row), _.present)
    }))
  }

  get _columnData() {
    const columns = [],
      columnGroups = _.groupBy(this._parseColumnData(), (column) => {
        return column.column_group
      })

    _.forEach(columnGroups, (groupColumns, group) => {
      if (_.blank(group)) {
        groupColumns.forEach((column) => {
          columns.push(this._mapColumn(column))
        })
      } else {
        columns.push({
          title: group,
          columns: _.map(groupColumns, this._mapColumn)
        })
      }
    })

    columns.push({
      formatter: 'buttonCross', width: 40, align: 'center', cellClick: (_e, cell) => {
        cell.getRow().delete()
      }
    })

    return columns
  }

  get _data() {
    let data = []

    if (_.blank(this.inputTarget.value)) {
      _.times(Configurator_TabularDataController.MIN_COLUMNS, () => { data.push({}) })
    } else {
      data = JSON.parse(this.inputTarget.value)

      if (data.length < Configurator_TabularDataController.MIN_COLUMNS) {
        _.times(Configurator_TabularDataController.MIN_COLUMNS - data.length, () => { data.push({}) })
      }
    }

    return data
  }

  _parseColumnData() {
    return JSON.parse(this.data.get('columns'))
  }

  _mapColumn(column) {
    return {
      title: column.column_title,
      field: column.column_field,
      headerTooltip: column.column_legend,
      minWidth: column.column_width,
      ...JSON.parse(column.column_type)
    }
  }
}
