import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class QuizController extends Controller {
  static targets = ['form', 'submit', 'content', 'questionGroup', 'quizForm', 'completionMessage']

  submitQuiz() {
    h.fetch(this.formTarget.action, {
      body: JSON.stringify(this._params),
      method: 'post',
      headers: {
        'content-type': 'application/json'
      }
    }).then((response) => {
      if (response.ok) {
        response.text().then((html) => {
          this._fadeReplace(html)
        })

        return
      }

      throw response
    })
  }

  checkFormCompletion = _.debounce(() => {
    this._checkFormCompletion()
  }, 500)

  _checkFormCompletion() {
    const allAnswered = _.all(this.questionGroupTargets, (group) =>
      _.any($(group).find('input:hidden'), (questionChoice) =>
        !$(questionChoice).prop('disabled')))

    if (allAnswered) {
      $(this.submitTarget).removeClass('disabled')
    }

    $(this.submitTarget).prop('disabled', !allAnswered)
  }

  get _params() {
    return $(this.formTarget).serializeJSON()
  }

  _fadeReplace(html) {
    $(this.contentTarget).hide().html(html).fadeIn('slow')
  }

  showQuiz() {
    $(this.quizFormTarget).show()
    $(this.completionMessageTarget).hide()
  }
}
