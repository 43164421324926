import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class TitleUpdaterController extends Controller {
  connect() {
    _.each(this._titles, (value, key) => {
      this._titlesController.updateTitle(key, value)
    })
  }

  get _titlesController() {
    return h.getParentController(this.element, 'titles')
  }

  get _titles() {
    return h.parseData(this.data.get('titles'))
  }
}
