import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'
import { parseData } from 'lib/helpers'

export default class BusinessContinuityController extends Controller {
  static targets = ['form']

  connect() {
    StimulusReflex.register(this)
    this._updateHistory()
  }

  continue(event) {
    event.preventDefault()

    this.stimulate('BusinessContinuityReflex#continue', this._serializedForm)
      .then(() => this._updateHistory())
  }

  back(event) {
    event.preventDefault()

    this.stimulate('BusinessContinuityReflex#back', this._serializedForm)
      .then(() => this._updateHistory())
  }

  export(event) {
    event.preventDefault()

    const exportRedirect = event.currentTarget.href

    this.stimulate('BusinessContinuityReflex#export', this._serializedForm)
      .then(() => { SecuricyApp.window.location = exportRedirect })
  }

  popstate(event) {
    if (_.blank(event.state)) {
      return
    }

    event.preventDefault()

    this.stimulate(
      'BusinessContinuityReflex#navigate',
      _.merge(this._serializedForm, { configurator_page_offset: event.state.configurator_page_offset })
    )
  }

  navigate(event) {
    event.preventDefault()

    this.stimulate(
      'BusinessContinuityReflex#navigate',
      _.merge(this._serializedForm, { configurator_page_offset: parseData(event.target.dataset.configuratorPageOffset) })
    )
      .then(() => this._updateHistory())
  }

  afterReflex() {
    this._scrollToTop()
  }

  _updateHistory() {
    const value = parseData(this.formTarget.configurator_page_offset.value),
      state = { configurator_page_offset: value },
      path = Routes.businessContinuityPath(value)

    SecuricyApp.window.history.pushState(state, null, path)
  }

  _scrollToTop() {
    SecuricyApp.window.scrollTo(0, 0)
  }

  get _serializedForm() {
    return _.merge(
      $(this.formTarget).find(':input').not('[data-serialized-json]').serializeJSON(),
      $(this.formTarget).find(':input[data-serialized-json]').serializeJSON({
        parseWithFunction: (value) => {
          if (_.present(value)) {
            return JSON.parse(value)
          }
        }
      })
    )
  }
}
