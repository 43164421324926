
import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class FullScreenModalController extends Controller {
  static targets = ['container', 'content']

  initialize() {
    SecuricyApp.fullScreenModal = this
  }

  connect() {
    this._opened = false
    this._loaded = false
    this._keepNoOverflow = false
  }

  disconnect() {
    delete SecuricyApp.fullScreenModal
  }

  open(eventOrUrl) {
    if (_.present(eventOrUrl.preventDefault)) {
      eventOrUrl.preventDefault()
    }

    this._keepNoOverflow = SecuricyApp.document.body.classList.contains('no-overflow')

    this.opened = true

    this._loadContent(this._getModalURL(eventOrUrl))
      .then(() => { this.loaded = true })
  }

  close() {
    this.opened = false
    this.loaded = false
    this.contentTarget.innerHTML = ''
    this._keepNoOverflow = false
    SecuricyApp.document.body.classList.remove('no-overflow')
  }

  keydown(event) {
    if (event.key === 'Escape' && this.opened) {
      this.close()
      event.preventDefault()
    }
  }

  async _loadContent(url) {
    h.fetch(url)
      .then((response) => {
        if (response.ok) {
          response.text().then((html) => {
            this.contentTarget.innerHTML = html
          })

          return response
        }
      })
  }

  set opened(value) {
    this.containerTarget.classList.toggle('c-full-screen-modal--active', value)
    SecuricyApp.document.body.classList.toggle('no-overflow', value || this._keepNoOverflow)

    this._opened = value
  }

  get opened() {
    return this._opened
  }

  set loaded(value) {
    this.containerTarget.classList.toggle('c-full-screen-modal--loaded', value)
  }

  get loaded() {
    return this._loaded
  }

  _getModalURL(eventOrUrl) {
    if (_.present(eventOrUrl.currentTarget)) {
      return eventOrUrl.currentTarget.getAttribute('href')
    }

    return eventOrUrl
  }
}
