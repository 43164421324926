
import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class NotificationLinkController extends Controller {
  static targets = ['notification', 'counter']

  click(event) {
    const path = event.currentTarget.dataset.href,
      target = event.currentTarget.dataset.linkTarget

    this._markAsRead(event.currentTarget.dataset.id)

    if (_.blank(path)) {
      $(event.currentTarget).remove()
      this._updateCounters(this._notificationCount())

      return
    }

    if (target === 'new') {
      h.openLocation(path)
    } else {
      h.setWindowLocation(path)
    }
  }

  _markAsRead(notificationId) {
    h.fetch(Routes.notificationPath(notificationId), {
      method: 'PATCH'
    })
  }

  _notificationCount() {
    return this.notificationTargets.length
  }

  _updateCounters(count) {
    if (count === 0) {
      count = ''
    }

    this.counterTargets.forEach((counter) => {
      counter.innerHTML = count
    })
  }
}
