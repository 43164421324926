
import { Controller } from 'stimulus'
import { setWindowLocation } from 'lib/helpers'

export default class GuidedSetup_ProcessCheckController extends Controller {
  connect() {
    this._guidedSetupChannel = SecuricyApp.cable.subscriptions.create('GuidedSetup::ProcessCheckChannel', {
      received: (data) => this.handleCheck(data)
    })

    this._pollInterval = setInterval(() => this.poll(), 5000)
  }

  poll() {
    this._guidedSetupChannel.perform('check')
  }

  handleCheck(data) {
    if (data) {
      this.disconnect()

      setWindowLocation(Routes.dashboardPath())
    }
  }

  disconnect() {
    clearTimeout(this._pollInterval)

    this._guidedSetupChannel.unsubscribe()
  }
}
