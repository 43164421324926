import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class User_ShowController extends Controller {
  static targets = ['content']

  refreshChangeableHtml() {
    this.refreshCurrentTab()
    this.fetchUserDetailsArea()
  }

  refreshCurrentTab() {
    h.callStimulusAction('.model-show-container', 'tab', 'refreshCurrentTab')
  }

  fetchUserDetailsArea() {
    h.callStimulusAction('.model-show-details-area', 'refresh-content', 'refreshContent')
  }

  get _objectId() {
    return this.data.get('object-id')
  }
}
