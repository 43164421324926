
import { Controller } from 'stimulus'
import { windowCallback } from 'lib/helpers'

export default class RecaptchaController extends Controller {
  initialize() {
    this._initializeRecaptcha()
  }

  _initializeRecaptcha() {
    if (this._isRecaptchaInitialized) {
      return
    }

    const callback = windowCallback(() => {
      this._insertRecaptcha()
    })

    $('head').append($('<script>', {
      src: `https://www.google.com/recaptcha/api.js?render=${this._siteKey}&onload=${callback}`
    }))
  }

  _insertRecaptcha() {
    this._recaptcha.ready(() => {
      this._recaptcha.execute(this._siteKey, { action: this._action }).then((token) => {
        $(this.element).prepend($('<input>', {
          type: 'hidden',
          name: this._recaptchaTokenField,
          value: token
        }))
      })
    })
  }


  get _recaptcha() {
    return SecuricyApp.window.grecaptcha
  }

  get _siteKey() {
    return this.data.get('site-key')
  }

  get _action() {
    return this.data.get('action')
  }

  get _recaptchaTokenField() {
    return _.presence(this.data.get('token-field')) || 'recaptcha_token'
  }

  get _isRecaptchaInitialized() {
    return !!this._recaptcha
  }
}
