
import { Controller } from 'stimulus'

export default class Configurator_InputSelectorController extends Controller {
  static targets = ['name', 'pendingText']

  selectInput(event) {
    if (this.hasNameTarget) {
      this.nameTarget.value = event.currentTarget.dataset.name
      this.nameTarget.required = true
    }

    if (this.hasPendingTextTarget) {
      this.pendingTextTarget.disabled = true
    }
  }

  updateOther(event) {
    if (this.hasNameTarget) {
      this.nameTarget.value = event.currentTarget.value
    }
  }
}
