import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class ReportControl_RequirementsController extends Controller {
  remove() {
    const dataStore = this._dataStoreController

    dataStore.remove(this._dataStorePath, this.element.dataset.resourceId)
  }

  get _dataStorePath() {
    return this.element.dataset.linkedScope
  }

  get _dataStoreController() {
    return h.getParentController(this.element, 'data-store')
  }
}
