
import { Controller } from 'stimulus'
import $ from 'jquery'
import 'slick-carousel'

export default class ToDoController extends Controller {
  static targets = ['main']

  connect() {
    $(this.mainTarget).slick({
      dots: true,
      prevArrow: '<div class="slick-prev ripple"><i class="material-icons">arrow_back</i></div>',
      nextArrow: '<div class="slick-next ripple"><i class="material-icons">arrow_forward</i></div>',
      infinite: false,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 577,
          settings: {
            arrows: false
          }
        }
      ]
    })
  }
}
