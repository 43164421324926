
import { Controller } from 'stimulus'
import Mustache from 'mustache'

export default class Configurator_DelegationOfAuthorityController extends Controller {
  static targets = ['roleTemplate', 'roles', 'delegateTemplate']

  connect() {
    this.roleIndex = _.parseInt($(this.element).data('role-index'))

    if (this.roleIndex === 0) {
      this.addRole()
    }
  }

  addRole() {
    const html = Mustache.render(this._roleTemplateCompiled, {
      role_index: this.roleIndex
    })

    $(this.rolesTarget).append(html)

    this.roleIndex += 1

    return false
  }

  get delegateTemplateCompiled() {
    if (!this.__delegateTemplateCompiled) {
      this.__delegateTemplateCompiled = this.delegateTemplateTarget.innerHTML

      Mustache.parse(this.__delegateTemplateCompiled)
    }

    return this.__delegateTemplateCompiled
  }

  get _roleTemplateCompiled() {
    if (!this.__roleTemplateCompiled) {
      this.__roleTemplateCompiled = this.roleTemplateTarget.innerHTML

      Mustache.parse(this.__roleTemplateCompiled)
    }

    return this.__roleTemplateCompiled
  }
}
