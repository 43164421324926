
import FetchObserver from 'lib/fetch_observer'
import { setWindowLocation } from 'lib/helpers'

export default class SessionTimeoutFetchObserver extends FetchObserver {
  observeFetch() {
    if (this._timedOut) {
      _.tryIt(SecuricyApp.loadingSpinner, 'show')
      setWindowLocation(Routes.userSessionPath())

      return false
    }
  }

  get _timedOut() {
    return this.response.status === 401
  }
}
