import { Controller } from 'stimulus'

export default class TitlesController extends Controller {
  static targets = ['title']

  updateTitle(key, value) {
    const title = this.titleTargets.find((titleTarget) => titleTarget.dataset.titleName === key)

    if (title) {
      title.innerHTML = value
    }
  }
}
