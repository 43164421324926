
import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class UserCollectorController extends Controller {
  static targets = ['counter', 'button', 'placeholder', 'collector', 'template']

  connect() {
    this._dataStoreController.registerObserver(this._scope, this)
  }

  updateState() {
    let values = this._dataStoreController.get(this._scope),
      selectedRows = []

    if (_.blank(values)) {
      values = []
    }

    values.forEach((userId) => {
      selectedRows.push(this._constructUserRow(userId))
    })

    selectedRows = _.sortBy(selectedRows, (row) => row.userName)
    const collectorContent = _.map(selectedRows, (row) => row.content).join('')

    if (selectedRows.length > 0) {
      this.collectorTarget.innerHTML = collectorContent
      this.placeholderTarget.classList.add('hidden')
      this.collectorTarget.classList.remove('hidden')
      this.counterTarget.innerHTML = `Users (${selectedRows.length})`
      this.buttonTarget.classList.remove('hidden')
    } else {
      this.collectorTarget.innerHTML = ''
      this.placeholderTarget.classList.remove('hidden')
      this.collectorTarget.classList.add('hidden')
      this.counterTarget.innerHTML = 'Users'
      this.buttonTarget.classList.add('hidden')
    }
  }

  _constructUserRow(userId) {
    const formRow = SecuricyApp.document.querySelector(`#user-selector-form [data-user-id='${userId}']`),
      data = {
        avatarColorClass: formRow.querySelector('.avatar').className,
        avatarCharacter: formRow.querySelector('.avatar').innerHTML,
        userName: formRow.querySelector('.title').innerHTML,
        userId: userId
      }

    return {
      content: h.renderTemplate(this.templateTarget.innerHTML, data),
      userName: data.userName
    }
  }

  get _dataStoreController() {
    return h.getParentController(this.element, 'data-store')
  }

  get _scope() {
    return this.element.dataset.scope
  }
}
