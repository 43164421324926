
import { Controller } from 'stimulus'
import { renderTemplate } from 'lib/helpers'

export default class NotificationController extends Controller {
  static targets = ['container', 'content', 'template']

  connect() {
    this._notificationChannel = SecuricyApp.cable.subscriptions.create('NotificationChannel', {
      received: (data) => this.showNotification(data)
    })
  }

  disconnect() {
    this._notificationChannel.unsubscribe()
  }

  showNotification(notification) {
    this.containerTarget.innerHTML = renderTemplate(this.templateTarget.innerHTML, notification)

    this._setupAttributes(notification.attributes)

    this._setupDisappear()
  }

  _setupDisappear() {
    if (!this._hasDisappearsAfter) {
      return
    }

    if (_.present(this.fade)) {
      clearTimeout(this.fade)
    }

    this.fade = setTimeout(() => {
      this.contentTarget.addEventListener('transitionend', () => {
        this.contentTarget.remove()
      }, { once: true })

      this.contentTarget.classList.add('fading-notification')
    }, this._disappearsAfter)
  }

  _setupAttributes(attributes) {
    if (_.present(attributes) && _.present(this.containerTarget.firstElementChild)) {
      _.forOwn(attributes, (value, key) => {
        this.containerTarget.firstElementChild.setAttribute(key, value)
      })
    }
  }

  get _disappearsAfter() {
    if (!this._hasDisappearsAfter) {
      return
    }

    return this.data.get('disappears-after') * 1000
  }

  get _hasDisappearsAfter() {
    return _.present(this.data.get('disappears-after'))
  }
}
