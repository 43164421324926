import LinkedCheckCirclesController from 'controllers/linked_check_circles_controller'
import * as h from 'lib/helpers'

export default class Groups_Projects_Edit_TaskSelectorController extends LinkedCheckCirclesController {

  toggle() {
    if (!h.parseData(this.element.dataset.checked)) {
      this._dataStoreController.add('selectedProjects', this.element.dataset.projectId)
    }

    super.toggle()
  }
}
