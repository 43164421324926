import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class Projects_Tasks_My_ToggleStatusController extends Controller {
  static targets = ['button', 'error']

  toggle_status() {
    $(this.buttonTarget).addClass('p100')

    h.fetch(this.data.get('url'), { method: 'PATCH' })
      .then((response) => {
        if (response.ok) {
          this.refreshChangeableHtml()

          return
        }

        $(this.buttonTarget).removeClass('p100')

        throw response
      })

      .catch(() => {
        this._showError()
      })
  }

  refreshChangeableHtml() {
    h.callStimulusAction('.info-sidebar', 'refresh-content', 'refreshContent')
    h.callStimulusAction('.model-show-container', 'tab', 'refreshCurrentTab')
  }

  _showError() {
    $(this.errorTargets).html('There was an error updating the tasks status')
  }
}
