import { Controller } from 'stimulus'

export default class StepController extends Controller {
  static targets = ['step', 'previous', 'next']

  connect() {
    this.currentStep = 1
    this.lastStep = this._lastStep()
  }

  previous() {
    if (this.currentStep === 1) {
      return
    }

    this.currentStep -= 1

    this._toggleCurrentStep()
  }

  next(event) {
    if (!this._validateRequiredFields()) {
      return false
    }

    event.preventDefault()

    if (this.currentStep === this.lastStep) {
      return
    }

    this.currentStep += 1
    this._toggleCurrentStep()
  }

  _validateRequiredFields() {
    let loopStep, emptyRequiredFields = {}
    const currentStep = this.currentStep

    _.forEach(this.stepTargets, function (step) {
      loopStep = _.parseInt(step.dataset.step)
      if (loopStep === currentStep) {
        emptyRequiredFields = $(step).find(':input[required]:empty').filter((_k, el) => { return el.value === '' })
      }
    })

    return !emptyRequiredFields.length
  }

  _toggleCurrentStep() {
    let loopStep
    const currentStep = this.currentStep

    _.forEach(this.stepTargets, function (step) {
      loopStep = _.parseInt(step.dataset.step)
      if (loopStep === currentStep) {
        $(step).show()
      } else {
        $(step).hide()
      }
    })

    if (currentStep === 1) {
      $(this.previousTarget)
        .prop('disabled', true)
        .addClass('disabled')
        .addClass('c-button c-button--link')
        .removeClass('c-button c-button--primary')
    } else {
      $(this.previousTarget)
        .prop('disabled', false)
        .removeClass('disabled')
    }

    $(this.nextTarget).toggle(currentStep !== this.lastStep)
  }

  _lastStep() {
    let lastStep = 0, loopStep

    _.forEach(this.stepTargets, function (step) {
      loopStep = _.parseInt(step.dataset.step)

      if (loopStep > lastStep) {
        lastStep = loopStep
      }
    })

    return lastStep
  }
}
