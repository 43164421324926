import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class Policies_History_ChangeLogController extends Controller {
  static targets = ['changeLog', 'content']

  click(event) {
    $(this.changeLogTargets).removeClass('c-change-log-list__item--selected')
    $(event.currentTarget).addClass('c-change-log-list__item--selected')

    this.refreshChangeLogBody(event.currentTarget.dataset.changeLogId)
  }

  refreshChangeLogBody(changeLogId) {
    const url = this._changeLogUrl(changeLogId)

    this.contentTarget.dataset.refreshContentUrl = url
    h.callStimulusAction(this.contentTarget, 'refresh-content', 'refreshContent')
  }

  _changeLogUrl(changeLogId) {
    return Routes.policyChangeLogPath(this.element.dataset.policyId, changeLogId)
  }
}
