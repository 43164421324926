
import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class FetchFormController extends Controller {

  connect() {
    this.preSubmitCallbacks = []
  }

  submit(event) {
    event.preventDefault()

    Promise.all(this.preSubmitCallbacks.map((callback) => {
      if (callback._context) {
        return callback.call(callback._context)
      }

      return callback()
    })).then(() => {
      h.fetch(this.element.action, {
        body: JSON.stringify($(this.element).serializeJSON()),
        method: this._method,
        headers: {
          'content-type': 'application/json'
        }
      }).then((response) => {
        this._response = response

        if (response.ok) {
          this._callSuccessAction()
        } else {
          this._callErrorAction()
        }
      })
    }).catch(() => this._callErrorAction())
  }

  attachPreSubmitCallback(hook, context) {
    hook._context = context
    this.preSubmitCallbacks.push(hook)
  }

  _callSuccessAction() {
    this._callAction('success-action')
  }

  _callErrorAction() {
    this._callAction('error-action')
  }

  _callAction(fetchAction) {
    if (!this.data.has(fetchAction)) {
      return
    }

    h.executeExternalAction(this.data.get(fetchAction), this.element)
  }

  get lastResponse() {
    return this._response
  }

  get _method() {
    const methodInput = this.element.querySelector('input[name=_method]')
    let method = 'GET'

    if (methodInput) {
      method = methodInput.value
    } else if (this.element.method) {
      method = this.element.method
    }

    return method.toUpperCase()
  }
}
