import ImageAttachmentController from 'controllers/image_attachment_controller'
import * as h from 'lib/helpers'
import { DirectUpload } from '@rails/activestorage'

export default class FetchFormImageAttachmentController extends ImageAttachmentController {
  connect() {
    // no-op - prevents non-fetch direct upload implementation from initializing
  }

  select() {
    super.select()
    this._upload = new DirectUpload(this._file, this._url, this)
    this._registerSubmitPromise()
  }

  progress(event) {
    var percent = 100 * event.loaded / event.total
    this.indicatorTarget.style.width = `${percent}%`
  }

  // This abomination of a method name is a requirement of ActiveStorage DirectUpload :/
  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress',
      event => this.progress(event))
  }

  uploadFile() {
    return new Promise((resolve, reject) => {
      this._state = 'uploading'

      this._upload.create((error, blob) => {
        if (error) {
          this._state = 'error'

          reject()
        } else {
          this.inputTarget.value = null
          this._state = 'complete'
          this.hiddenInputTarget.disabled = false
          this.hiddenInputTarget.value = blob.signed_id

          resolve()
        }
      })
    })
  }

  get _fetchFormController() {
    return h.getController(this._form, 'fetch-form')
  }

  _registerSubmitPromise() {
    if (this._fetchFormController) {
      this._fetchFormController.attachPreSubmitCallback(this.uploadFile, this)
    }
  }
}
