
export default class ExternalAction {
  constructor(value, element) {
    const match = value.match(/^(.+?)#(.+?)(@(.+?))?$/)

    this.identifier = match[1]
    this.action = match[2]
    this.target = match[4] || element
    this.element = element
  }

  execute() {
    _.tryIt(SecuricyApp.application.getControllerForElementAndIdentifier($(this.target)[0], this.identifier), this.action, this._createEvent())
  }

  _createEvent() {
    return {
      type: 'externalaction',
      target: this.element,
      currentTarget: this.element
    }
  }
}
