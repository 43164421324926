import { Controller } from 'stimulus'

export default class QuickFilterController extends Controller {
  static targets = ['input', 'filterable']

  initialize() {
    this.filter = _.debounce(this.filter, 150)
  }

  filter() {
    const query = this.inputTarget.value,
      parts = query.split(' ')

    if (_.blank(query)) {
      this._showAll()
      return
    }

    this.filterableTargets.forEach((target) => {
      if (this._matches(parts, target.dataset.filterValue)) {
        this._show(target)
      } else {
        this._hide(target)
      }
    })
  }

  clear() {
    this.inputTarget.value = ''
    this.filter()
  }

  _show(target) {
    target.classList.remove('filtered')
  }

  _hide(target) {
    target.classList.add('filtered')
  }

  _showAll() {
    this.filterableTargets.forEach((target) => {
      this._show(target)
    })
  }

  _matches(parts, filterValue) {
    return _.every(parts, (part) => {
      return filterValue.toLowerCase().indexOf(part.toLowerCase()) !== -1
    })
  }
}
