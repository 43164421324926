import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class RefreshContentController extends Controller {
  static targets = ['content', 'placeholder']

  refreshContent() {
    if (!this.hasContentTarget) {
      return
    }

    this._showPlaceholder()

    h.fetch(this._url)
      .then((response) => {
        if (response.ok) {
          response.text().then((html) => {
            this._fadeReplace(html)
          })

          return
        }

        throw response
      })

      .catch(() => {
        $(this.contentTarget).html('There was an error loading the content')
      })
  }

  _fadeReplace(html) {
    $(this.contentTarget).hide().html(html).fadeIn('slow')
  }

  _showPlaceholder() {
    if (this.hasPlaceholderTarget) {
      this.contentTarget.innerHTML = this.placeholderTarget.innerHTML
    }
  }

  get _url() {
    let url = this.data.get('url')
    const query = this._query

    if (_.isObject(query) && _.any(query)) {
      url = `${url}?${$.param(query)}`
    }

    return url
  }

  get _query() {
    return h.parseData(this.data.get('query'))
  }
}
