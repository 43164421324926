import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class Policies_Tasks_My_ToggleStatusController extends Controller {
  static targets = ['button', 'error']

  toggle_status() {
    $(this.buttonTarget).addClass('p100')

    h.fetch(this.data.get('url'), { method: 'PATCH' })
      .then((response) => {
        if (response.ok) {
          h.getParentController(this.element, 'project--show')
            .refreshChangeableHtml()

          return
        }

        $(this.buttonTarget).removeClass('p100')

        throw response
      })

      .catch(() => {
        this._showError()
      })
  }

  _showError() {
    $(this.errorTargets).html('There was an error updating the tasks status')
  }
}
