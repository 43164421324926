
import FetchObserver from 'lib/fetch_observer'
import { setWindowLocation } from 'lib/helpers'

export default class RedirectFetchObserver extends FetchObserver {
  observeFetch() {
    if (this._wasRedirected) {
      _.tryIt(SecuricyApp.loadingSpinner, 'show')
      setWindowLocation(this._redirectPath)

      return false
    }
  }

  get _wasRedirected() {
    return this.response.redirected && this.response.url.match(this._redirectPathRegexp)
  }

  get _redirectPathRegexp() {
    return new RegExp(`${this._redirectPath}`)
  }

  get _redirectPath() {
    throw 'abstract method not implemented'
  }
}
