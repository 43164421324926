
import { Controller } from 'stimulus'

export default class SlideOutTabController extends Controller {
  static targets = ['tab', 'content']

  connect() {
    const tabName = this.data.get('initialTab'),
      tab = this._findTabByName(tabName)

    if (_.blank(tab)) {
      this._selectTab(this.tabTargets[0])
      return
    }

    this._selectTab(tab)
  }

  clickTab(event) {
    this._selectTab(event.currentTarget)

    this.element.dispatchEvent(new Event('slideOutTab_clickTab'))
  }

  _selectTab(tab) {
    const tabName = tab.dataset.tabName

    $(this.contentTargets).removeClass('selected-content')
    $(this.tabTargets).removeClass('selected')
    $(this._findTabContentByName(tabName)).addClass('selected-content')
    $(tab).addClass('selected')
  }

  _findTabByName(tabName) {
    return this._findTargetByName(tabName, 'tabTargets')
  }

  _findTabContentByName(tabName) {
    return this._findTargetByName(tabName, 'contentTargets')
  }

  _findTargetByName(tabName, targetType) {
    return this[targetType].find((tab) => tab.dataset.tabName === tabName)
  }
}
