import { Controller } from 'stimulus'

export default class ButtonSpinnerController extends Controller {
  static targets = ['button']

  static STATES = {
    submitting: 'c-button-spinner--submitting',
    done: 'c-button-spinner--done',
    error: 'c-button-spinner--error'
  }

  submit() {
    if (this.buttonDisabled) {
      return
    }

    this.disableButton = true

    this._state = 'submitting'
  }

  stopSpinner() {
    this.disableButton = false

    this._state = ''
  }

  done() {
    this._state = 'done'
  }

  error(timeout = 3000) {
    this._state = 'error'

    setTimeout(() => {
      this.stopSpinner()
    }, timeout)
  }

  get buttonDisabled() {
    return this.buttonTarget.disabled
  }

  set disableButton(value) {
    this.buttonTargets.forEach((button) => {
      button.disabled = value
      $(button).toggleClass('no-click', value)
    })
  }

  set _state(state) {
    this.buttonTargets.forEach((button) => {
      button.classList.remove(... _.values(ButtonSpinnerController.STATES))
      button.classList.add(ButtonSpinnerController.STATES[state])
    })
  }
}
