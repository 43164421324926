import LinkedCheckCirclesController from 'controllers/linked_check_circles_controller'
import * as h from 'lib/helpers'

export default class ProjectLeaderCheckCirclesController extends LinkedCheckCirclesController {

  connect() {
    const dataStore = this._dataStoreController

    if (this._checked) {
      dataStore.add(this._memberScope, this._memberValue)
    }

    dataStore.registerObserver(this._memberScope, this)
  }

  toggle() {
    if (!this._checked) {
      this._dataStoreController.add(this._memberScope, this._memberValue)
    }

    this._checked = !this._checked
  }

  updateState() {
    const isMember = _.includes(this._dataStoreController.get(this._memberScope), this._memberValue)

    if (!isMember) {
      this._checked = false
    }
  }

  get _memberScope() {
    return h.parseData(this.element.dataset.memberScope)
  }

  get _memberValue() {
    return this.element.dataset.memberValue
  }
}
