
import { Controller } from 'stimulus'
import * as h from 'lib/helpers'

export default class LoadDraftController extends Controller {
  static targets = ['textArea', 'form', 'errorMessage', 'step']

  connect() {
    this._previousValue = null
  }

  loadSavedDraft() {
    this._setReadOnly(true)

    this._hideErrorMessage()

    h.fetch(this._getDraftPath, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if (response.ok) {
        return response.json().then((json) => {
          const { content } = json

          if (!content) {
            throw 'content not sent'
          }

          this._handleDraftData(content)
        })
      }

      throw response
    }).catch(() => {
      this._showErrorMessage('Could not load draft content')
      this._setReadOnly(false)
    })
  }

  dismiss() {
    this._resetSteps()
  }

  discardDraft() {
    if (SecuricyApp.window.confirm('Are you sure you want to discard this draft?')) {
      this._hideErrorMessage()

      h.fetch(this._getDraftPath, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if (response.ok) {
          this._resetSteps()
          this._editor.value(this._previousValue)
        }

        throw response
      }).catch(() => {
        this._showErrorMessage('Could not discard draft')
      }).then(() => {
        this._setReadOnly(false)
      })
    }
  }

  keepDraft() {
    this._hideErrorMessage()

    h.fetch(this._applyDraftPath, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if (response.ok) {
        this._resetSteps()
      }

      throw response
    }).catch(() => {
      this._showErrorMessage('Could not apply draft')
    }).then(() => {
      this._setReadOnly(false)
    })
  }

  _handleDraftData(content) {
    this._previousValue = this._editor.value()
    this._editor.value(content)
    this.textAreaTarget.value = content

    this._nextStep()
  }

  _nextStep() {
    if (this._currentStep + 1 >= this.stepTargets.length) {
      return
    }

    this._currentStep = this._currentStep + 1
  }

  _resetSteps() {
    this._currentStep = 0
    this.stepTargets.forEach((step) => {
      step.style.display = 'none'
    })
  }

  get _currentStep() {
    return Math.max(0, this.stepTargets.findIndex((step) =>
      step.style.display !== 'none'))
  }

  set _currentStep(selectedIndex) {
    this.stepTargets.forEach((step, index) => {
      if (index === selectedIndex) {
        step.style.display = 'block'
      } else {
        step.style.display = 'none'
      }
    })
  }

  _showErrorMessage(message = null) {
    if (this.hasErrorMessageTarget) {
      this.errorMessageTarget.style.display = 'block'
      this.errorMessageTarget.innerHTML = message
    }
  }

  _hideErrorMessage() {
    if (this.hasErrorMessageTarget) {
      this.errorMessageTarget.style.display = 'none'
      this.errorMessageTarget.innerHTML = ''
    }
  }

  _setReadOnly(readOnly) {
    this._codemirror.doc.cantEdit = readOnly
  }

  get _codemirror() {
    return this._editor.codemirror
  }

  get _editor() {
    return this._markdownEditorFieldController.editor
  }

  get _markdownEditorFieldController() {
    return h.getParentController(this.textAreaTarget, 'markdown-editor-field')
  }

  get _applyDraftPath() {
    return Routes[`apply${this._resourceType}DraftPath`](this._resourceId, this._draftId)
  }

  get _getDraftPath() {
    return Routes[`${_.camelCase(this._resourceType)}DraftPath`](this._resourceId, this._draftId)
  }

  get _draftId() {
    return this.element.dataset.draftId
  }

  get _resourceId() {
    return this.element.dataset.resourceId
  }

  get _resourceType() {
    return this.element.dataset.resourceType
  }
}
