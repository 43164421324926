
import FetchObserver from 'lib/fetch_observer'
import GoogleAnalytics from 'lib/google_analytics'
import { parseData } from 'lib/helpers'

export default class GoogleAnalyticsFetchObserver extends FetchObserver {
  observeFetch() {
    const gaEvents = _(parseData(this.response.headers.get('x-google-analytics')))
      .castArray()
      .compact()
      .value()

    if (_.present(gaEvents)) {
      gaEvents.forEach((gaEvent) => GoogleAnalytics.send(gaEvent))
    }
  }
}
